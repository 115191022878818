import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GrayButton from "./GrayButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";


export default function OptionsMenuComponent(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <GrayButton
                title="OPÇÕES"
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu"
                className={props.className}
            />
            <Menu
                sx={{
                    "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                        background: temaEscuro && BLACK_TABLE_THEME
                    }
                }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    onClick={() => {}}
                    data-testid="percentage-dre"
                    className="button_percentage"
                >
                    Histórico
                </MenuItem>
                <MenuItem
                    onClick={() => {}}
                    data-testid="percentage-dre"
                    className="button_percentage"
                >
                    Exportar
                </MenuItem>
                <MenuItem
                    onClick={() => {}}
                    data-testid="percentage-dre"
                    className="button_percentage"
                >
                    Importar
                </MenuItem>
            </Menu>
        </div>
    );
}