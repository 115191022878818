import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
	GRAY_LABEL_UX_THEME,
	GRAY_LABEL_UX,
	YELLOW_BG_UX,
    formatterCurrency,
    DASH_BAR_COLOR,
    BLACK_TABLE,
    paperStyle,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD
} from "../../shared/utils";
import GrayButton from "../../components/Buttons/GrayButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import api from "../../axios";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import Skeleton from "@mui/material/Skeleton";
import Tour from "reactour";
import { steps } from "./TourDespesas";
import {showSnackMessage} from "../../actions/SnackActions";
import {changeEstabelecimento} from "../../actions/StoreActions";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import Paper from "@mui/material/Paper";
import DespesasMobile from "./DespesasMobile";
import DataGrid from "../../components/DataGrid/DataGrid";
import DespesasModal from "../../components/Modal/DespesasModal";
import ResetBoletoModal from "../../components/Modal/ResetBoletoModal";
import DespesasFooter from "../../components/Footers/DespesasFooter";
import Header from "../../components/Titles/Header";
import LastUpdate from "../../components/Titles/LastUpdate";


const Despesas = () => {
    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState("Pendente");
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] =  useState(false);
    const [locationId, setLocationId] = useState(0);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isDesktop = useSelector(state => state.AuthReducer.isDesktop);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const [boletos, setBoletos] = useState([]);
    const [boletoStatus, setBoletoStatus] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openModalReset, setOpenModalReset] = useState(false);
    const [lastUpdateDDA, setLastUpdateDDA] = useState("");

    const handleOpenModalBoleto = (boletoStatus) => {
        setBoletoStatus(boletoStatus);
        setOpenModal(true);
    };

    const handleChange = (event, value) => {
        setSelectedTab(value);
        getBoletos(value);
        setItensSelecionados([]);
    };
    
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setLocationId(parseInt(query.get("estabelecimento_id")));
        if (locationId != 0 && locationId != null) {
            dispatch(changeEstabelecimento(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        if (estabelecimentoId !== 0 && estabelecimentoId !== null) {
            getLastUpdateDDA();
            updateBoletos();
            getBoletos(selectedTab);
        }
    }, [estabelecimentoId]);

    const updateBoletos = () => {
        setLoading(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId
        };

        api.UpdateBoletos(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Boletos carregados com sucesso!", severity: "success"}));
        }).catch((e) => {
            if (e.response.status !== 403) {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
            }
            setLoading(false);
        });
    };

    const getLastUpdateDDA = () => {
        setLoading(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId
        };

        api.GetLastUpdateDDA(dataRequest).then(response => {
            setLoading(false);
            setLastUpdateDDA(response.data.data_modificacao);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));

        });
    };

    useEffect(() => {
        setItensSelecionados([]);
        setBoletos([]);
    }, [selectedTab]);

    const getBoletos = (status_value) => {
        setLoading(true);
        let dataRequest = {};
        if (estabelecimentoId !== 0) {
            dataRequest = {
                estabelecimento_id: estabelecimentoId,
                status: status_value
            };
        }
        else if (locationId !== 0 && locationId != null) {
            dataRequest = {
                estabelecimento_id: estabelecimentoId,
                status: status_value
            };
        }
        api.GetBoletos(dataRequest).then(response => {
            let dataResponse = response.data;
            let columnsFiltered = dataResponse.columns;
            columnsFiltered[1] = {
                field: "valor",
                headerName: "Valor",
                flex: 1,
                type: "number",
                align: "center",
                headerAlign: "center",
                valueFormatter: (params) => formatterCurrency(params, 2),
            };

            setColumns(columnsFiltered);
            setData(dataResponse.boletos);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
        });
    };

    const copyLink = () => {
        try {
            let url = window.location.href;
            navigator.clipboard.writeText(url);
            dispatch(showSnackMessage({ message: "Link copiado com sucesso!" }));
        } catch (e) {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        }
    };

    const changeOpen = () => {
        setOpen(!open);
    };

    const openHistoric = () => {
        const url = "/historico_despesas";
        const params = { estabelecimento_id: estabelecimentoId};
        const queryString = new URLSearchParams(params).toString();
        const fullUrl = `${url}?${queryString}`;
        window.open(fullUrl, "_blank");
    };

    return (
        <React.Fragment>
            {
                isDesktop ? (
                    <div className="main" data-testid="despesas">
                        <LastUpdate lastUpdate={lastUpdateDDA} loading={loading} />
                        <Header title="Despesas"/>
                        {
                            (estabelecimentoId === 0 && (locationId === 0 || locationId == null)) ? (
                                <SelectEstabelecimento />
                            ) : (
                                <React.Fragment>
                                    {
                                        loading ? (
                                            <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
                                        ) : (
                                            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}>
                                                <Grid container xs={12} spacing={2} sx={{mb: 3, mt: 0}}>
                                                    <Grid item xs={6} sx={{backgroundColor: "transparent"}}>
                                                        <Tabs
                                                            value={selectedTab}
                                                            onChange={handleChange}
                                                            TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                                            className="buttonTabs"
                                                        >
                                                            <Tab
                                                                value={"Pendente"}
                                                                label="Pendentes"
                                                                sx={{
                                                                    borderRadius: "20px",
                                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                                    backgroundColor: selectedTab === "Pendente" ? YELLOW_BG_UX : "transparent",
                                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                        color: GRAY_LABEL_UX,
                                                                    },
                                                                }}
                                                            />
                                                            <Tab 
                                                                value={"Aceito"}
                                                                label="Aceitos"
                                                                sx={{
                                                                    borderRadius: "20px",
                                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                                    backgroundColor: selectedTab === "Aceito" ? YELLOW_BG_UX : "transparent",
                                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                        color: GRAY_LABEL_UX,
                                                                    },
                                                                }}
                                                            />
                                                            <Tab 
                                                                value={"Cancelado"}
                                                                label="Recusados"
                                                                sx={{
                                                                    borderRadius: "20px",
                                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                                    backgroundColor: selectedTab === "Cancelado" ? YELLOW_BG_UX : "transparent",
                                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                        color: GRAY_LABEL_UX,
                                                                    },
                                                                }}
                                                            />
                                                            {(isOperator || isAdmin) && (
                                                                <Tab
                                                                    value={"Ignorado"}
                                                                    label="Ignorados"
                                                                    sx={{
                                                                        borderRadius: "20px",
                                                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                                        backgroundColor: selectedTab === "Ignorado" ? YELLOW_BG_UX : "transparent",
                                                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                            color: GRAY_LABEL_UX,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        </Tabs>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display: "flex", alignItems: "center", justifyContent: "end", gap: 2, backgroundColor: "transparent"}}>
                                                        <GrayButton
                                                            title={"Histórico"}
                                                            startIcon={<ImportContactsIcon />}
                                                            onClick={() => openHistoric()}
                                                            className="buttonCopyLink"
                                                        />
                                                        {
                                                            (isOperator || isAdmin) && (
                                                                <GrayButton
                                                                    title={"Copiar Link"}
                                                                    startIcon={<ContentCopyIcon />}
                                                                    onClick={() => copyLink()}
                                                                    className="buttonCopyLink"
                                                                />
                                                            )
                                                        }
                                                        {/* <GrayButton
                                                            isRedirect
                                                            path="/upload_boletos"
                                                            title={"Arquivo"}
                                                            startIcon={<UploadFileIcon />}
                                                            className="buttonArquivo"
                                                        /> */}
                                                        <IconButton
                                                            style={{ background: "#ECC94B", borderRadius: "4px" }}
                                                            onClick={() => changeOpen()}
                                                            data-testid="button-guia"
                                                        >
                                                            <LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px" }} />
                                                        </IconButton>
                                                        <Tour
                                                            steps={steps}
                                                            isOpen={open}
                                                            onRequestClose={changeOpen}
                                                            accentColor={DASH_BAR_COLOR}
                                                            startAt={0}
                                                            rounded={3}
                                                            showNavigation={false}
                                                            inViewThreshold={60}
                                                            className={temaEscuro && "tour"}
                                                            badgeContent={(curr, tot) => `${curr}/${tot}`}
                                                        />
                                                        <DespesasModal
                                                            selectedTab={selectedTab}
                                                            getBoletos={getBoletos}
                                                            boletoStatus={boletoStatus}
                                                            boletos={boletos}
                                                            openModal={openModal}
                                                            setOpenModal={setOpenModal}
                                                        />
                                                        <ResetBoletoModal
                                                            selectedTab={selectedTab}
                                                            boletos={boletos}
                                                            openModal={openModalReset}
                                                            setOpenModal={setOpenModalReset}
                                                            getBoletos={getBoletos}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} height={"calc(100vh - 196px - 72px)"}>
                                                        <DataGrid
                                                            checked
                                                            rows={(row) => row.id}
                                                            columns={columns}
                                                            data={data}
                                                            customRowSelectionModelChange={(item) => setBoletos(item)}
                                                            customFooter={
                                                                <DespesasFooter
                                                                    handleOpenModalBoleto={handleOpenModalBoleto}
                                                                    selectedTab={selectedTab}
                                                                    setOpenModalReset={setOpenModalReset}
                                                                    boletos={boletos}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    }
                                </React.Fragment>
                                
                            )
                        }
                    </div>
                ) : (
                    <DespesasMobile 
                        setSelectedTab={setSelectedTab} 
                        selectedTab={selectedTab} 
                        handleChange={handleChange} 
                        data={data} 
                        getBoletos={getBoletos} 
                        itensSelecionados={itensSelecionados} 
                        setItensSelecionados={setItensSelecionados} 
                    />
                )
            }
        </React.Fragment>
        
    );
};

export default React.memo(Despesas);