import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import {
	textCompleteStyleBlack,
	textCompleteStyle,
	BLUE_THEME,
	GRAY_BG_UX,
	GRAY_LABEL_UX,
	styleButton,
	WHITE_THEME_BLACK,
	BORDER_BUTTON,
	styleDefaultText,
	WHITE_ESCRITA_THEME,
	translateAutocomplete
} from "../../shared/utils";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { defaultFilters } from "./defaultFilters";
import api from "../../axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const FilterWithTag = (props) => {
	const {options, setOptions, testIdAll, previousValue, optionFilter, handleSubmit, monthsFiltered, limitSelection, placeholderProp, testId, isDefaultFilter, advancedFilter=false, handleAdvancedFilter, isConciliacao, widthSetting=""} = props;

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	const [value, setValue] = useState([]);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const selectAllOptions = isConciliacao ? options : [{value: "all", label: "Selecionar Todos"}, ...options];
	const stylePaper = {
		backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
		color: temaEscuro ? "#FFF" : "#4A5568",
	};
	const defaultLimitSelection = 2;
	const limitTags = limitSelection ? limitSelection : defaultLimitSelection;

	const defaultPlaceHolderText = "Selecione";
	const placeholderText = placeholderProp ? placeholderProp : defaultPlaceHolderText;
	const placeholderFilter = "Filtro Padrão";
	const [monthsCompetencia, setMonthsCompetencia] = useState([]);
	const [filterList, setFilterList] = useState([]);

	useEffect(() => {
		if(isDefaultFilter) {
			GetMonthsList();
		}
	}, [storeId]);

	const GetMonthsList = () => {
		const dataRequest = {
			lojas: [storeId]
		};

		api.GetMesesCompetencia(dataRequest).then((request) => {
			let dataResponse = request.data;
			setMonthsCompetencia(dataResponse);

			if (isDefaultFilter) {
				setFilterList(getNewMonthsList(dataResponse));
			}
		});
	};

	const getNewMonthsList = (dataResponse) => {
		let returnList = [];
		for (let index = 0; index < defaultFilters.length; index++) {
			if (defaultFilters[index].value <= dataResponse.length) {
				returnList.push(defaultFilters[index]);
			}
		}
		return returnList;
	};

	useEffect(() => {
		if (advancedFilter) {
			setValue(defaultFilters.find((filter) => filter.value == previousValue.length));
		}
		else {
			if(previousValue?.length && options.length){
				setValue(previousValue);
				setOptions(previousValue.map((option) => option.value));
			}
		}
	}, [options, previousValue]);

	const handleChange = (event, selectedOptions) => {
		if (advancedFilter && selectedOptions != null) {

			setValue(selectedOptions);

			const totalMonths = [];

			for (let index = 0; index < selectedOptions.value; index++) {
				totalMonths.push(monthsCompetencia[index].label);
			}

			setOptions(totalMonths);
		}
		else if (selectedOptions != null) {
			if (!isConciliacao || selectedOptions.length <= 3) {
				if (selectedOptions.map((option) => option.value).includes("all")) {
					setOptions(options.map((option) => option.value));
					setValue(options);
				} else {
					setOptions(selectedOptions.map((option) => option.value));
					setValue(selectedOptions);
				}
			} else {
				// Se selectedOptions for maior que 3 e isConciliacao for true, não faz nada
				if (isConciliacao && selectedOptions.length > 3) {
					return;
				}
			}
		}
	};

	const handleClick = () => {
		handleAdvancedFilter();
		setValue([]);
	};

	return(
		<React.Fragment>
			<Grid container data-testid={testIdAll} sx={{display: "flex", alignItems: "center", width: widthSetting != "" ? widthSetting : "475px"}} spacing={2}>
				<Grid item xs>
					{
						advancedFilter ? (
							<Autocomplete
								size="small"
								noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
								id="checkboxes-tags-demo"
								data-testid={testId ? testId : "filter-with-tag"}
								options={filterList}
								slotProps={{
									paper: {style: stylePaper}
								}}
								style={{backgroundColor: temaEscuro && BLUE_THEME}}
								value={value}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField
										{...params}
										sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
										label={placeholderFilter}
										InputLabelProps={{
											style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
										}}
									/>
								)}
							/>
						) : (
							<Autocomplete
								size="small"
								noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
								multiple
								id="checkboxes-tags-demo"
								data-testid={testId ? testId : "filter-with-tag"}
								options={selectAllOptions}
								disableCloseOnSelect
								limitTags={value.length >= 10 ? defaultLimitSelection - 1 : limitTags}
								isOptionEqualToValue={(option, value) => option.value === value.value}
								getOptionLabel={(option) => option.label}
								slotProps={{
									paper: {style: stylePaper}
								}}
								style={{backgroundColor: temaEscuro && BLUE_THEME}}
								renderOption={
									(props, option, { selected }) => (
										<li {...props} style={{ color: temaEscuro ? GRAY_BG_UX : GRAY_LABEL_UX}}>
											<Checkbox
												icon={icon}
												checkedIcon={checkedIcon}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option.label}
										</li>
									)
								}
								value={value}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField
										{...params}
										sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
										label={placeholderText}
										InputLabelProps={{
											style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
										}}
									/>
								)}
							/>
						)
					}
					{
						isDefaultFilter && (
							<Grid container xs={6} spacing={1} sx={{display: "flex", alignItems: "center", marginTop: 1, alignContent: "center", marginBottom: "20px", marginLeft: 0}}>
								<Grid item data-testid="default-filter" className="outros_filtros" sx={{cursor: "pointer"}} onClick={handleClick}>
									<a style={{...styleDefaultText, width: "auto", fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME}}>Filtro Padrão</a>
								</Grid>
								<Grid item sx={{cursor: "pointer"}} onClick={handleClick}>
									{advancedFilter ? (
										<RemoveCircleOutlineIcon sx={{fontSize: "14px", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, paddingTop: .5}}/>
										) : (
										<ControlPointIcon sx={{fontSize: "14px", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, paddingTop: .5}}/>
									)}
								</Grid>
							</Grid>
						)
					}
				</Grid>
				{
					!optionFilter &&
					(
						<Grid item>
							<Button
								data-testid="button-filter-with-tag"
								variant="contained"
								className={props.className}
								onClick={() => handleSubmit(monthsFiltered)}
								style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor: temaEscuro && BORDER_BUTTON}}>
								<FilterListOutlinedIcon/>
								Filtrar
							</Button>
						</Grid>
					)
				}
			</Grid>
		</React.Fragment>

	);
};

export default React.memo(FilterWithTag);