import React, { useState, useMemo } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME, smallPaperStyle,
    paperStyleMobile, getCurrentDate, GREEN_SUCCESS_UX, BORDER_TABLE, WHITE_ESCRITA_THEME, modalTextTitle, WHITE_TABLE, GRAY_LABEL_UX, modalTextSubTitle
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import moment from "moment";
import Countdown from "react-countdown";
import Divider from "@mui/material/Divider";



const DespesasModal = (props) => {
    const {
        openModal,
        setOpenModal,
        boletoStatus,
        boletos,
        getBoletos,
        selectedTab
    } = props;
    const [passwordTyped, setPasswordTyped] = useState("");
    const [openWhatsAppConfirmation, setOpenWhatsAppConfirmation] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment(getCurrentDate()).toDate());
    const [resendCode, setResendCode] = useState(false);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isDesktop = useSelector(state => state.AuthReducer.isDesktop);

    const dispatch = useDispatch();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOnChange = (event) => {
        setPasswordTyped(event.target.value);
    };

    const sendWppAuthMessage = () => {
        setOpenWhatsAppConfirmation(true);

        api.SendWhatsAppAuthenticationMessage().then(() => {
            dispatch(showSnackMessage({message: "Código enviado por Whatsapp", severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
        });
    };

    const handleChangeBoletoStatus = () => {
        const dataRequestChangeBoleto = {
            id: boletos,
            estabelecimento_id: estabelecimentoId,
            status: boletoStatus,
            prev_status: selectedTab,
            data_pagamento: selectedDate instanceof Date ? moment(selectedDate).format("DD-MM-YYYY") : selectedDate,
        };

        const dataRequestVerifyCode = {
            verification_code: passwordTyped,
        };

        api.VerifyWhatsAppCode(dataRequestVerifyCode).then(() => {
            api.ChangeBoletosStatus(dataRequestChangeBoleto).then(() => {
                dispatch(showSnackMessage({message: `Boleto(s) alterado(s) para o status: ${boletoStatus}.`}));
                setOpenModal(false);
                getBoletos(selectedTab);
            }).catch(() => {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
            });
        }).catch((error) => {
            if (error.response.status === 401) {
                dispatch(showSnackMessage({message: "Código expirado! Tente novamente.", severity: "error"}));
            }
        });
    };

    const handleDayMonthYear = (ano, mes, dia) => {
        setSelectedDate(`${dia}-${mes}-${ano}`);
    };

    const time = useMemo(() => {
        return Date.now() + 30000;
    }, [resendCode]);

    const formatTime = (time) => {
        if (time < 10) {
            return `0${time}`;
        }
        return time;
    };

    const resendVerificationCode = () => {
        setResendCode(true);
        sendWppAuthMessage();
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="despesas-modal"
            >
                {
                    openWhatsAppConfirmation ? (
                        <Paper style={{...(isDesktop ? smallPaperStyle : paperStyleMobile), backgroundColor: temaEscuro && BLUE_THEME}}>
                            <Grid container sx={{display: "flex", gap: 2}}>
                                <Grid item xs={12} sx={{...modalTextTitle, paddingTop: 2, color: temaEscuro ? WHITE_TABLE : GRAY_LABEL_UX}}>
                                    AVISO
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom: 2}}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : WHITE_ESCRITA_THEME}} /> 
                                </Grid>
                                <Grid item xs={12} sx={{...modalTextSubTitle, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                    Enviamos, via WhatsApp, um código para confirmar a sua identidade.
                                </Grid>
                                <Grid item xs={12} sx={{...modalTextSubTitle, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                    Por favor, cole o código abaixo:
                                </Grid>
                                {resendCode === true && (
                                    <Grid item xs={12} sx={{...modalTextSubTitle, color: GREEN_SUCCESS_UX, marginTop: 2}}>
                                        Um novo código foi enviado ao seu Whatsapp.
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="*****"
                                        onChange={handleOnChange}
                                        endAdornment={
                                            <InputAdornment>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        data-testid="despesas-modal-input"
                                        size="small"
                                    />
                                </Grid>
                                {resendCode === true && (
                                    <Grid item xs={12}>
                                        <Countdown date={time} onComplete={() => setResendCode(false)} renderer={({ minutes, seconds }) => {
                                            return <span style={{color: "#718096", fontWeight: "400", fontSize: "16px", paddingTop: 2}}>Aguarde {formatTime(minutes)}:{formatTime(seconds)} segundos para reenviar o código novamente</span>;
                                        }}/>
                                    </Grid>
                                )}
                                <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 5}}>
                                    <Grid item>
                                        <GrayButton
                                            onClick={() => resendVerificationCode()}
                                            title="Reenviar código"
                                            disabled={resendCode}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <YellowButton
                                            onClick={handleChangeBoletoStatus}
                                            testId="categoria-resolver-btn"
                                            title="Enviar"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ) : (
                        <Paper style={{...(isDesktop ? smallPaperStyle : paperStyleMobile), backgroundColor: temaEscuro && BLUE_THEME}}>
                            <Grid container sx={{display: "flex", gap: 2}}>
                                <Grid item xs={12} sx={{...modalTextTitle, paddingTop: 2, color: temaEscuro ? WHITE_TABLE : GRAY_LABEL_UX}}>
                                    AVISO
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom: 2}}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : WHITE_ESCRITA_THEME}} /> 
                                </Grid>
                                <Grid item xs={12} sx={{...modalTextSubTitle, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                    Enviamos, via WhatsApp, um código para confirmar a sua identidade.
                                </Grid>
                                <Grid item xs={12}>
                                    <FilterDayMonthYear
                                        handleSubmit={handleDayMonthYear}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 5}}>
                                <Grid item>
                                    <GrayButton
                                        onClick={() => setOpenModal(false)}
                                        title="Cancelar"/>
                                </Grid>
                                <Grid item>
                                    <YellowButton
                                        onClick={() => sendWppAuthMessage()}
                                        testId="categoria-resolver-btn"
                                        title="Confirmar"
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                }
            </Modal>
        </React.Fragment>
    );
};

export default React.memo(DespesasModal);