import {
	CHANGE_STORE,
	CHANGE_LOJAS,
	CHANGE_MODIFIED,
	CHANGE_ESTABELECIMENTO,
} from "./types";

export const changeStore = (storeId) => {
	return {
		type: CHANGE_STORE,
		payload: storeId
	};
};

export const changeEstabelecimento = (estabelecimentoId) => {
	return {
		type: CHANGE_ESTABELECIMENTO,
		payload: estabelecimentoId
	};
};

export const changeLojas = (lojas) => {
	return {
		type: CHANGE_LOJAS,
		payload: lojas
	};
};

export const changeModified = (dataModified) => {
	return {
		type: CHANGE_MODIFIED,
		payload: dataModified
	};
};
