import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {BLUE_THEME, GRAY_BG_UX, WHITE_ESCRITA_THEME, BORDER_BUTTON} from "../../shared/utils";
import {useSelector} from "react-redux";

const InputsApelidoTipo = (props) => {

    const {inputTipoConta, isBancoEdit, contaDetails, setInputTipoConta, tipoConta, inputApelido, setInputApelido, disabledTipo, disabledApelido} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <React.Fragment>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Tipo</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={disabledTipo ? contaDetails.tipo : inputTipoConta}
                    label="Tipo"
                    defaultValue={contaDetails.tipo}
                    disabled={isBancoEdit || disabledTipo}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={e => {
                        if (!disabledTipo) {
                            setInputTipoConta(e.target.value);
                        }
                    }}
                >
                    {tipoConta.map((tpConta) => {
                        return (
                            <MenuItem data-testid="menu-item" key={tpConta} value={tpConta}>
                                {tpConta}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <TextField
                label="Apelido"
                id="outlined-size-small"
                defaultValue={contaDetails.apelido}
                size="small"
                value={!disabledApelido && inputApelido}
                onChange={(event) => {
                    setInputApelido(event.target.value);
                }}
                disabled={disabledApelido}
                sx={{
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                    width: "250px",
                    "& .MuiInputBase-input": {
                        color: temaEscuro && WHITE_ESCRITA_THEME,
                    },
                }}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
            />
        </React.Fragment>
    );
};

export default React.memo(InputsApelidoTipo);