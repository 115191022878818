import React from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BLUE_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    LINE_TABLE,
    RED_INFO
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";


const StepOneCartaoCredito = (props) => {

    const {
        contasPagamento,
        bandeiras,
        inputApelido,
        setInputApelido,
        inputUltimosDigitos,
        setInputUltimosDigitos,
        inputCartaoBandeira,
        setInputCartaoBandeira,
        inputCartaoContaPagamento,
        setInputCartaoContaPagamento,
        setInputCartaoEmissor,
        inputCartaoEmissor,
        bancos
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Cadastre as informações do Cartão de Crédito</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Cadastre uma descrição, informe a conta corrente para pagamento e a bandeira do seu Cartão de Crédito.</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <TextField
                    label="Apelido do Cartão de Cŕedito"
                    id="outlined-size-small"
                    size="small"
                    value={inputApelido}
                    onChange={(event) => {
                        setInputApelido(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                />
                <TextField
                    label="últimos 4 números do cartão"
                    id="outlined-size-small"
                    size="small"
                    value={inputUltimosDigitos}
                    onChange={(event) => {
                        setInputUltimosDigitos(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                    inputProps={{ maxLength: 4 }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl size="small" sx={{width: "250px"}} required>
                    <InputLabel 
                        id="demo-simple-select-label" 
                        sx={{
                            color: temaEscuro && BORDER_BUTTON,
                            fontSize: "15px",
                            "& .MuiFormLabel-asterisk": {
                                color: RED_INFO,
                            }
                        }}
                    >
                        Bandeira
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inputCartaoBandeira}
                        label="Bandeira"
                        sx={{
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            width: "250px",
                            "& .MuiInputBase-input": {
                                color: temaEscuro && WHITE_ESCRITA_THEME,
                            },
                        }}
                        onChange={(e) => setInputCartaoBandeira(e.target.value)}
                    >
                            {bandeiras.map((bandeira) => {
                            return (
                                <MenuItem data-testid="menu-item" key={bandeira} value={bandeira}>
                                    {bandeira}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
                <FormControl size="small" sx={{width: "250px"}} required>
                    <InputLabel 
                        id="demo-simple-select-label" 
                        sx={{
                            color: temaEscuro && BORDER_BUTTON,
                            fontSize: "15px",
                            "& .MuiFormLabel-asterisk": {
                                color: RED_INFO,
                            }
                        }}
                    >
                        Emissor do Cartão
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inputCartaoEmissor}
                        label="Emissor do Cartão"
                        sx={{
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            width: "250px",
                            "& .MuiInputBase-input": {
                                color: temaEscuro && WHITE_ESCRITA_THEME,
                            },
                        }}
                        onChange={(e) => setInputCartaoEmissor(e.target.value)}
                    >
                            {bancos.map((bandeira) => {
                            return (
                                <MenuItem data-testid="menu-item" key={bandeira} value={bandeira}>
                                    {bandeira}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{width: "250px"}}>
                    <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Conta padrão para o pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inputCartaoContaPagamento}
                        label="Conta padrão para o pagamento"
                        sx={{
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            width: "250px",
                            "& .MuiInputBase-input": {
                                color: temaEscuro && WHITE_ESCRITA_THEME,
                            },
                        }}
                        onChange={(e) => setInputCartaoContaPagamento(e.target.value)}
                    >
                            {contasPagamento.map((contaP) => {
                            return (
                                <MenuItem data-testid="menu-item" key={contaP} value={contaP}>
                                    {contaP}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default React.memo(StepOneCartaoCredito);