import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "application/json"
	}
});

const instanceFormData = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "multipart/form-data",
	}
});

instanceFormData.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	return config;
}, err => {
	return Promise.reject(err);
});

instance.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	return config;
}, err => {
	return Promise.reject(err);
});

export default {
    // ======== AUTENTICATION ========
	GetLogin(data) {
		return instance.post("auth/login", data);
	},
	SendResetPwdEmail(data){
		return instance.post("auth/email_pwd_code", data);
	},
	GetCheckCode(data){
		return instance.get("auth/email_pwd_code", {params: data});
	},
	GetResetPwd(data){
		return instance.put("auth/reset_pwd", data);
	},
	Logout(data) {
		return instance.delete("auth/logout", data);
	},
	PutChangePassword(data) {
		return instance.put("auth/change_pwd", data);
	},
	GetSession(){
		return instance.get("auth/active_session");
	},
	DeleteSession(data){
		return instance.delete("auth/active_session", {data:data});
	},
	PutUpdateDeviceToken(data){
		return instance.put("auth/update_device_token", data);
	},
	// ======== USER =========
	UserInfo(){
		return instance.get("user/detail");
	},
	UpdateUser(data){
		return instanceFormData.put("user/update_user", data);
	},
	GetNotifications(){
		return instance.get("user/notifications");
	},
	ReadNotification(data){
		return instance.patch("user/notifications", data);
	},
	// ======== LOJA =========
	GetLojas(){
		return instance.get("loja/all");
	},
	GetEstabelecimentos(data){
		return instance.get("loja/estabelecimentos", {params: data});
	},
	SendUploadCertificado(data){
		return instanceFormData.post("loja/upload_certificado", data);
	},
	// ======== BOLETOS =========
	GetBancosBoletos(){
		return instance.get("boletos/banco/all");
	},
	GetBoletos(data){
		return instance.get("boletos/boletos", {params: data});
	},
	ChangeBoletosStatus(data){
		return instance.post("boletos/boletos", data);
	},
	ResetBoletosStatus(data){
		return instance.patch("boletos/boletos", data);
	},
	SendWhatsAppAuthenticationMessage(){
		return instance.post("boletos/codigo_verificacao");
	},
	VerifyWhatsAppCode(data){
		return instance.get("boletos/codigo_verificacao", {params: data});
	},
	UpdateBoletos(data){
		return instance.patch("boletos/update_boletos", data);
	},
	// ======== BASE DADOS =========
	GetLastUpdateDDA(data){
		return instance.get("base_dados/last_update_dda", {params: data});
	},
	PostGerarCompra(data){
		return instance.post("base_dados/generate_compra", data);
	},
	GetFornecedores(data){
		return instance.get("base_dados/fornecedores", {params: data});
	},
	PostCreateFornecedores(data){
		return instance.post("base_dados/fornecedores", data);
	},
	GetCategorias(data){
		return instance.get("base_dados/categorias", {params: data});
	},
	PostCreateCategoria(data){
		return instance.post("base_dados/categorias", data);
	},
	GetProdutosServicos(data){
		return instance.get("base_dados/produtos_servicos", {params: data});
	},
	PostCreateProdutoServico(data){
		return instance.post("base_dados/produtos_servicos", data);
	},
	// ======== CONTA ==========
	GetContas(data){
		return instance.get("conta/contas", {params: data});
	},
	DeleteConta(data){
		return instance.delete("conta/contas", {data:data});
	},
	EditConta(data){
		return instance.put("conta/contas", data);
	},
	CreateConta(data){
		return instance.post("conta/contas", data);
	},
	// ======== NOTAS =========
	GetNotasRecebidos(data){
		return instance.get("compras/notas_recebidos", {params: data});
	},
	GetNotasCompras(data){
		return instance.get("compras/notas_compras", {params: data});
	},
	PostNotasRecebidos(data){
		return instance.post("compras/notas_recebidos", data);
	},
	PatchNotasRecebidos(data){
		return instance.patch("compras/notas_recebidos", data);
	},
	GetNotaByKey(data){
		return instance.get("compras/nota_by_id", {params: data});
	},
	ImportNotaByKey(data){
		return instance.post("compras/nota_by_id", data);
	},
	// EXTRATO MOVIMENTACOES
	GetExtratoMovimentacoes(data){
		return instance.post("base_dados/extrato_movimentacoes", data);
	},
};