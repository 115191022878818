import React from "react";
import {
    WHITE_ESCRITA_THEME,
} from "../../shared/utils";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

function LastUpdate({lastUpdate, loading}) {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        (
            loading ? (
                <Box sx={{display: "flex", justifyContent: "flex-end", mb: "14px"}}>
                    <Skeleton variant="rectangular" width={"17%"} height={"17px"} />
                </Box>
            ) : (
                <p style={{display: "flex", justifyContent: "flex-end", fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME, marginTop: 0, marginRight: "10px"}}>
                    Última atualização: {lastUpdate}
                </p>
            )
        )
    );
}

export default React.memo(LastUpdate);