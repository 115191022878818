import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import VoxelLogoAlt from "../../assets/images/VoxelLogoAlt.png";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import React from "react";
import {BLUE_THEME, GRAY_LABEL_UX} from "../../shared/utils";

const ToastDisplay = (props) => {
    const {toastConfig, notification, isDDA} = props;

    const reloadPage = () => {
        toast.dismiss(toastConfig.id);
        window.location.reload();
    };

    return (
        <div style={{width: isDDA ? "450px" : "350px"}}>
            <Grid container style={{alignItems: "center"}}>
                <Grid item xs={isDDA ? 1 : 2}>
                    <Avatar alt="Voxel" src={VoxelLogoAlt}/>
                </Grid>
                <Grid item xs={isDDA ? 9 : 8} sx={{pl: isDDA && 1.5}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h1 style={styles.title}>{notification.title}</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h1 style={styles.body}>{notification.body}</h1>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    {isDDA ? (
                    <Button variant="text" onClick={() => reloadPage()} style={styles.button}>Atualizar</Button>
                    ) : (
                    <Button variant="text" onClick={() => toast.dismiss(toastConfig.id)} style={styles.button}>Fechar</Button>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

const styles = {
    title: {
        color: BLUE_THEME,
        fontSize: 14,
        fontWeight: "bold",
        margin: "0 0 2px 0"
    },
    body: {
        color: GRAY_LABEL_UX,
        fontSize: 12,
        margin: 0
    },
    button: {
        backgroundColor: "transparent",
        color: GRAY_LABEL_UX,
        fontWeight: 600,
        boxShadow: "none",
    }
};

export default React.memo(ToastDisplay);