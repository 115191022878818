import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
	DASH_KPI_COLOR,
	DASH_KPI_DATA_COLOR,
	DASH_KPI_NEGATIVE_COLOR,
	DASH_KPI_POSITIVE_COLOR,
	dashLeftTitle,
	formatterCurrency,
	WHITE_THEME_BLACK,
	paperDash,
	BLUE_THEME,
	WHITE_ESCRITA_THEME,
	EXM_SUBTITLE_GRAY,
	BLUE_INFO_UX,
} from "../../shared/utils";

const EXMKPI = (props) => {
	const { valorTotal, periodo, testId } = props;
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	return (
		<Paper sx={{ ...paperDash, ...dashLeftTitle, backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none" }} className={props.className}>
			<Grid container data-testid={testId}>
				<Grid item xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
					<Grid container spacing={1} className="footer_periodo">
						<Grid item xs={12}>
							<span style={{ ...styles.title, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>Totais do período</span>
						</Grid>
						<Grid item xs={12}>
							<span style={{ ...styles.subTitle, ...(temaEscuro && { color: EXM_SUBTITLE_GRAY }) }}>{periodo}</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container spacing={1} className="footer_valor">
						<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
							<span style={{ ...styles.subTitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor total do período (R$)</span>
						</Grid>
						<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
							<span style={{ ...styles.title, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>{formatterCurrency(valorTotal, 2)}</span>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		</Paper>
	);
};

// Create styles
const styles = {
	title: {
		fontSize: "16px",
		fontWeight: 500,
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "14px",
		fontWeight: 400,
		color: DASH_KPI_DATA_COLOR
	},
	positive: {
		color: DASH_KPI_POSITIVE_COLOR
	},
	negative: {
		color: DASH_KPI_NEGATIVE_COLOR
	},
	total: {
		color: BLUE_INFO_UX
	},
};

export default React.memo(EXMKPI);