import React  from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BLUE_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    LINE_TABLE,
    RED_INFO
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";


const StepTwoCartaoCredito = (props) => {

    const {
        inputDiaVencimento,
        setInputDiaVencimento,
        inputDiaFechamento,
        setInputDiaFechamento,
        inputCartaoBandeira,
        inputApelido,
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Informações sobre a sua Fatura</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Para controlar os gastos do seu Cartão de Crédito, informe os dias de fechamento e vencimento, e o valor da última fatura.</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <TextField
                    label="Bandeira do Cartão"
                    variant="outlined"
                    size="small"
                    defaultValue={inputCartaoBandeira}
                    disabled
                    sx={{width: "250px"}}
                />
                <TextField
                    label="Apelido"
                    variant="outlined"
                    size="small"
                    defaultValue={inputApelido}
                    disabled
                    sx={{width: "250px"}}
                />
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <TextField
                    label="Dia do fechamento"
                    id="outlined-size-small"
                    size="small"
                    value={inputDiaFechamento}
                    onChange={(event) => {
                        setInputDiaFechamento(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                    inputProps={{ maxLength: 2 }}
                />
                <TextField
                    label="Dia do vencimento"
                    id="outlined-size-small"
                    size="small"
                    value={inputDiaVencimento}
                    onChange={(event) => {
                        setInputDiaVencimento(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                    inputProps={{ maxLength: 2 }}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(StepTwoCartaoCredito);