import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    /* eslint-disable no-undef */
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    /* eslint-enable no-undef */
};

let getFirebaseToken;
let onMessageListener;


// Initialize Firebase
try {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    getFirebaseToken = () => {
        // eslint-disable-next-line no-undef
        return getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY});
    };

    onMessageListener = () => (
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                resolve(payload);
            });
        })
    );
}
catch (e){
    // eslint-disable-next-line no-console
    console.error(`not able to initialize firebase: ${e}`);

    getFirebaseToken = () => (new Promise(() => null));
    onMessageListener = () => (new Promise(() => null));
}

export {getFirebaseToken, onMessageListener};