import React from "react";

export default function PoliticaPrivacidade() {

	return (
		<div className="main" style={{color: "#3f3f3f"}}>
			<h1><b>POLÍTICA DE PRIVACIDADE</b></h1>

			<h2>Este site é mantido e operado por Voxel Gestão.</h2>
			<h4>
				Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).
				Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:
				- Quem deve utilizar nosso site- Quais dados coletamos e o que fazemos com eles;- Seus direitos em relação aos seus dados pessoais; e- Como entrar em contato conosco.
			</h4>

			<p>
				<b>1. Dados que coletamos e motivos da coleta</b> <br/>
			Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.<br/>

			1. Dados pessoais fornecidos expressamente pelo usuário. <br/>
			Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso site:<br/>
				- Nome completo - E-mail - Telefone
			A coleta destes dados ocorre nos seguintes momentos:<br/>
			Quando o usuário firma o contrato com a Voxel
			Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
			Para que o usuário tenha acesso ao sistema
			2. Dados pessoais obtidos de outras formas
			Nós coletamos os seguintes dados pessoais de nossos usuários:
			- Dados de geolocalização
			A coleta destes dados ocorre nos seguintes momentos:
			Quando o usuário loga no sistema
			Estes dados são coletados com as seguintes finalidades:
			- Personalizar a experiência do usuário
			3. Dados sensíveis
			Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
			4. Cookies
			Cookies são pequenos arquivos de texto baixados automaticamente em seu dispositivo quando você acessa e navega por um site. Eles servem, basicamente, para seja possível identificar dispositivos, atividades e preferências de usuários.
			Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.
			a. Cookies do site
			Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.
			As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.
			b. Cookies de terceiros
			Alguns de nossos parceiros podem configurar cookies nos dispositivos dos usuários que acessam nosso site.
			Estes cookies, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de forma personalizada, por meio da obtenção de dados de navegação extraídos a partir de sua interação com o site.
			O usuário poderá obter mais informações sobre os cookies de terceiro e sobre a forma como os dados obtidos a partir dele são tratados, além de ter acesso à descrição dos cookies utilizados e de suas características, acessando o seguinte link:
			Google-analytics
			As entidades encarregadas da coleta dos cookies poderão ceder as informações obtidas a terceiros.
			c. Gestão de cookies
			O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador. Mais informações sobre como fazer isso em alguns dos principais navegadores utilizados hoje podem ser acessadas a partir dos seguintes links:
			Internet Explorer:https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
			Safari:https://support.apple.com/pt-br/guide/safari/sfri11471/mac
			Google Chrome:https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
			Mozila Firefox:https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
			Opera:https://www.opera.com/help/tutorials/security/privacy/
			A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.
			5. Coleta de dados não previstos expressamente
			Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.
			Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.

			<br/><b>2. Compartilhamento de dados pessoais com terceiros</b><br/>
			Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.
			<br/><b>3. Por quanto tempo seus dados pessoais serão armazenados</b><br/>
			Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do site e as disposições legais ou regulatórias aplicáveis.
			Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.
				<br/><b>4. Bases legais para o tratamento de dados pessoais</b><br/>
			Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.
			Todas as Nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.
			1. Como o titular pode exercer seus direitos
			Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.
				<br/><b>5. Segurança dos dados pessoais armazenados</b><br/>
				A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
				<br/><b>6. Medidas de segurança no tratamento de dados pessoais</b><br/>
			Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.
			As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.
			Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
			- restrições de acesso ao banco de dados- criptografia de senhas e logins
			Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.
			De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.
				<br/><b>7. Alterações nesta política</b><br/>
			A presente versão desta Política de Privacidade foi atualizada pela última vez em: 28/09/2023.
			Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
			Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.
				<br/><b>8. Como entrar em contato conosco</b><br/>
			Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:
			E-mail: contato@voxelgestao.com
			Endereço postal: R. Copaíba, lote 01, Sala 714, DF Plaza - Águas Claras, Brasília
			</p>
		</div>

	);
}