import React  from "react";
import {smallPaperStyle, GRAY_LABEL_UX, styleTitleModal, BLUE_THEME, WHITE_ESCRITA_THEME, styleText, BORDER_TABLE} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";


const DeleteContaModal = (props) => {
    const {openDeleteModal, setOpenDeleteModal, deleteConta} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return(
        <Modal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="info-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "20%"}}>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: "700"}}>Excluir Conta</h3>
                    </Grid>
                    <Grid item xs={12} className="start">
                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Deseja mesmo EXCLUIR essa conta?</span>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 5, display: "flex", gap: 2, alignItems: "center", justifyContent: "end"}}>
                        <GrayButton 
                            title="Cancelar"
                            onClick={() => setOpenDeleteModal(false)}
                        />
                        <YellowButton
                            title="Confirmar"
                            onClick={() => deleteConta()}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );                  
};

export default DeleteContaModal;