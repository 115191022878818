import React, { useEffect }  from "react";
import {
    smallPaperStyle,
    BORDER_TABLE,
} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME} from "../../shared/utils";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import InputNumber from "../Input/InputNumber";
import InputSearch from "../Input/InputSearch";
import YellowButton from "../Buttons/YellowButton";
import GrayButton from "../Buttons/GrayButton";


const ParcelasModal = (props) => {
    const {openModal, setOpenModal, parcelas, setParcelas, formaPagamento, valorPagamento, condicaoPagamento, handleParcelas} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (parcelas === undefined) {
            handleParcelas();
        }
    }, [parcelas]);

    const handleDayMonthYear = (ano, mes, dia, idx) => {
        setParcelas(parcelas.map((parcela, idx_aux) => {
            if (idx_aux === idx) {
                parcela.data_vencimento = `${ano}-${mes}-${dia}`;
            }
            return parcela;
        }));
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, pt: 3, width: "50%", maxWidth: null}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <a>Condições de pagamento</a>
                        </Grid>
                        <Grid item xs={12} sx={{border: 0, borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, mb: 1, ml: 2}}/>
                        <Grid item sx={{mb: 10}}>
                            <Grid container spacing={3}>
                                <Grid item sx={{mr: "200px"}}>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <a>Forma de pagamento</a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a>{formaPagamento}</a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <a>Valor a pagar (R$)</a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a>{valorPagamento}</a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <a>Condição de pagamento</a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a>{condicaoPagamento}</a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <a>Parcelas</a>
                        </Grid>
                        <Grid item xs={12} sx={{border: 0, borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, mb: 2.5, ml: 2}}/>
                        <Grid item xs={12}>
                           <Grid container spacing={2}>
                               {
                                   parcelas.map((parcela, idx) => {
                                       return (
                                           <React.Fragment key={idx}>
                                               <Grid item xs={12}>
                                                   <Grid container spacing={2}>
                                                       <Grid item sx={{mt: 1.25}}>
                                                           <a style={{textAlign: "center"}}>{idx + 1}/{condicaoPagamento}</a>
                                                       </Grid>
                                                       <Grid item>
                                                          <FilterDayMonthYear
                                                              style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                              handleSubmit={handleDayMonthYear}
                                                              size={"small"}
                                                              width={"155px"}
                                                              iconPosition={"end"}
                                                              index={idx}
                                                              value={parcela.data_vencimento}
                                                          />
                                                       </Grid>
                                                       <Grid item>
                                                           <InputNumber
                                                               label={"Valor"}
                                                               value={parcela.valor}
                                                               setValue={(value) => (setParcelas(parcelas.map((parcela_aux, idx_aux) => {
                                                                   if (idx_aux === idx) {
                                                                       parcela_aux.valor = parseFloat(value);
                                                                   }
                                                                   return parcela_aux;
                                                               })))}
                                                               width={"150px"}
                                                               disabled={idx === (parcelas.length - 1)}
                                                               onBlur={() => handleParcelas(idx, parcela, "valor")}
                                                               error={parcela?.error}
                                                           />
                                                       </Grid>
                                                       <Grid item>
                                                           <InputNumber
                                                               label={"Percentual"}
                                                               value={parcela.percentual}
                                                               setValue={(value) => (setParcelas(parcelas.map((parcela_aux, idx_aux) => {
                                                                     if (idx_aux === idx) {
                                                                          parcela_aux.percentual = parseFloat(value);
                                                                     }
                                                                     return parcela_aux;
                                                               })))}
                                                               width={"150px"}
                                                               disabled={idx === (parcelas.length - 1)}
                                                               onBlur={() => handleParcelas(idx, parcela, "percentual")}
                                                               error={parcela?.error}
                                                           />
                                                       </Grid>
                                                       <Grid item>
                                                           <InputSearch
                                                               setSearchValue={(value) => (setParcelas(parcelas.map((parcela_aux, idx_aux) => {
                                                                   if (idx_aux === idx) {
                                                                       parcela_aux.descricao = value;
                                                                   }
                                                                   return parcela_aux;
                                                               })))}
                                                               searchValue={parcela.descricao}
                                                               width={"350px"}
                                                           />
                                                       </Grid>
                                                   </Grid>
                                               </Grid>
                                           </React.Fragment>
                                       );
                                   })
                               }
                           </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                <Grid item>
                                    <GrayButton
                                        onClick={() => setOpenModal(false)}
                                        title={"Cancelar"}
                                    />
                                </Grid>
                                <Grid item>
                                    <YellowButton
                                        onClick={() => setOpenModal(false)}
                                        title={"Concluir"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default ParcelasModal;