import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LastUpdate from "../../components/Titles/LastUpdate";
import Header from "../../components/Titles/Header";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import { changeStore } from "../../actions/StoreActions";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLACK_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME, BORDER_TABLE, WHITE_ESCRITA_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle, YELLOW_BG_UX
} from "../../shared/utils";
import NotasComprasTab from "../../components/Tabs/NotasComprasTab";
import NotasRecebidosTab from "../../components/Tabs/NotasRecebidosTab";


const NotasCompras = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [lastUpdateDDA, setLastUpdateDDA] = useState("");
    const [locationId, setLocationId] = useState(0);
    const [selectedTab, setSelectedTab] = useState("compras");
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setLocationId(parseInt(query.get("estabelecimento_id")));
        if (locationId !== 0 && locationId != null) {
            dispatch(changeStore(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        if (estabelecimentoId !== 0 && estabelecimentoId !== null) {
            getLastUpdateDDA();
        }
    }, [estabelecimentoId]);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const getLastUpdateDDA = () => {
        setLoading(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId
        };

        api.GetLastUpdateDDA(dataRequest).then(response => {
            setLoading(false);
            setLastUpdateDDA(response.data.data_modificacao);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));

        });
    };
    return (
        <React.Fragment>
            <div className="main" data-testid="despesas">
                <LastUpdate lastUpdate={lastUpdateDDA} loading={loading} />
                <Header title="Notas de Compras"/>
                {
                    (estabelecimentoId === 0 || (locationId === 0 || locationId == null)) ? (
                        <SelectEstabelecimento />
                    ) : (
                        <React.Fragment>
                            {
                                loading ? (
                                    <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
                                ) : (
                                    <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                                                <Tabs
                                                    value={selectedTab}
                                                    onChange={handleChange}
                                                    TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                                    className="buttonTabs"
                                                >
                                                    <Tab
                                                        value={"compras"}
                                                        label="Compras"
                                                        sx={{
                                                            borderRadius: "20px",
                                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                            backgroundColor: selectedTab === "compras" ? YELLOW_BG_UX : "transparent",
                                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                color: GRAY_LABEL_UX,
                                                            },
                                                        }}
                                                    />
                                                    <Tab
                                                        value={"recebidos"}
                                                        label="NF-E Recebidos"
                                                        sx={{
                                                            borderRadius: "20px",
                                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                            backgroundColor: selectedTab === "recebidos" ? YELLOW_BG_UX : "transparent",
                                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                color: GRAY_LABEL_UX,
                                                            },
                                                        }}
                                                    />
                                                </Tabs>
                                            </Grid>
                                            <Grid item xs={12} sx={{border: 0, borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, mb: 2.5, ml: 2}}/>
                                        </Grid>
                                        {selectedTab === "compras" && (
                                            <NotasComprasTab />
                                        )}
                                        {selectedTab === "recebidos" && (
                                            <NotasRecebidosTab />
                                        )}
                                    </Paper>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    );
};

export default NotasCompras;