import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
    paperStyle,
    styleGrid,
    PAPER_PADDING_THEME,
    BORDER_BUTTON,
    GRAY_BORDER_UX,
    GRAY_BG_UX,
    translateAutocomplete,
    textCompleteStyleBlack,
    textCompleteStyle,
    WHITE_THEME_BLACK,
    GRAY_STRONG_UX,
    BLUE_THEME,
    muiRootStyle,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD
} from "../../shared/utils";
import GrayButton from "../../components/Buttons/GrayButton";
import api from "../../axios";
import Paper from "@mui/material/Paper";
import Header from "../../components/Titles/Header";
import YellowButton from "../../components/Buttons/YellowButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import {showSnackMessage} from "../../actions/SnackActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";


function Certificados() {
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [estabeleciemntos, setEstabelecimentos] = useState([]);
    const [estabelecimentoSelected, setEstabelecimentoSelected] = useState(null);
    const [certificadoPassword, setCertificadoPassword] = useState("");
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const hiddenFileInput = React.useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getEstabelecimentos();
        }
    }, [estabelecimentoId]);

    const getEstabelecimentos = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId
        };

        api.GetEstabelecimentos(dataRequest).then((response) => {
            setEstabelecimentos(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const changeHandler = (event) => {
        let file = event.target.files[0];
        if (file != null) {
            if (file.type === "application/x-pkcs12" || file.type === "application/pkcs12") {
                setSelectedFile(file);
                setIsSelected(true);
            } else {
                dispatch(showSnackMessage({ message: "Tipo de certificado inválido", severity: "error" }));
            }
        }
    };

    const handleSubmission = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("estabelecimento", estabelecimentoSelected.value);
        formData.append("cert_password", certificadoPassword);
        formData.append("file_name", selectedFile.name);
        formData.append("selected_file", selectedFile);

        api.SendUploadCertificado(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Seu certificado foi carregado com sucesso!" }));
            setSelectedFile(null);
            setIsSelected(false);
            setCertificadoPassword("");
            setEstabelecimentoSelected(null);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    return (
        <div className="main">
            <Header title="Certificados"/>
            <Paper 
                data-testid="certificados" 
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}
            >
                <Grid container spacing={2} sx={{display: "flex", flexDirection: "column"}}>
                    <Grid item sx={{width: "400px"}}>
                        <FormControl fullWidth sx={{ ...styles.formControl, "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: BORDER_BUTTON }}}}>
                            <Autocomplete
                                data-testid="estabelecimento-input"
                                noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                disablePortal
                                disableClearable
                                PaperComponent={({ children }) => (
                                    <Paper
                                        style={{
                                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                        }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                id="combo-box-demo"
                                options={estabeleciemntos}
                                value={estabelecimentoSelected}
                                onChange={(event, newValue) => {
                                    setEstabelecimentoSelected(newValue);
                                }}
                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                renderInput={(params) => (
									<TextField
										{...params}
										sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
										InputLabelProps={{
											style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
										}}
										placeholder="Estabelecimento"
									/>
								)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        <TextField
                            data-testid="senha-certificado"
                            fullWidth
                            value={certificadoPassword}
                            onChange={(e) => setCertificadoPassword(e.target.value)}
                            label='Senha do certificado'
                            sx={{...(temaEscuro ? textCompleteStyleBlack : muiRootStyle), backgroundColor: temaEscuro && BLUE_THEME}}
                            InputLabelProps={{
                                style: {
                                    fontFamily: "Inter, sans-serif",
                                    color: temaEscuro ? BORDER_BUTTON : "#4A5568",
                                    fontWeight: "500"
                                }
                            }}
                        />
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        <GrayButton
                            testId="button-anexar-certificado"
                            title="Anexar certificado .pfx"
                            onClick={handleClick}
                            startIcon={<AttachFileOutlinedIcon/>}
                        />
                    </Grid>
                    <Grid item sx={{width: "400px"}}>
                        <input type="file"
                            accept=".pfx"
                            ref={hiddenFileInput}
                            onChange={changeHandler}
                            style={{display: "none", ml: 1}} 
                        />
                        {
                            isSelected &&
                                <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK}}>
                                    <span>Nome do arquivo: {selectedFile?.name}</span>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "end"}}>
                    <Grid item sx={styleGrid}>
                        {
                            loading ? (
                                <LoadingButton variant="outlined" loading >
                                    Enviar Certificado
                                </LoadingButton>
                            ) : (
                                <YellowButton
                                    onClick={handleSubmission}
                                    testId="button-enviar-certificado"
                                    title="Enviar Certificado"/>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};

export default Certificados;