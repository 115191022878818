import React, {useState, useEffect}  from "react";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
	GRAY_LABEL_UX, 
	WHITE_ESCRITA_THEME, 
	BLUE_THEME, 
	WHITE_TABLE, 
	GRAY_BG_UX, 
	BORDER_BUTTON,
	TITLE_REUNIAO,
	translateAutocomplete
} from "../../shared/utils";
import {changeEstabelecimento} from "../../actions/StoreActions";
import searchStore from "../../assets/images/search_store.png";
import searchStoreDark from "../../assets/images/search_store_dark.png";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";


const SelectEstabelecimento = () => {
	const [loadingLojas, setLoadingLojas] = useState(true);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const lojas = useSelector(state => state.StoreReducer.lojas);

	const dispatch = useDispatch();

	useEffect(() => {
		setLoadingLojas(false);
	}, [lojas]);

	const handleChange = (obj) => {
		dispatch(changeEstabelecimento(obj.value));
	};

	return(
		<div style={{...styles.center}}>
			{loadingLojas ? (
				<Skeleton variant="rectangular" height={950}/>
			) : (
				<React.Fragment>
					<Grid container rowSpacing={5} sx={{alignItems: "center"}} data-testid="data-content"> 
						<Grid item xs={12} sx={{paddingTop: "0 !important"}}>
							<img src={temaEscuro ? searchStoreDark : searchStore} />
						</Grid>
						<Grid item xs={12} >
							<span style={{...styles.text, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Selecione uma loja para visualizar os dados</span>
						</Grid>
						{ 
							lojas == null ? (
								<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
									<Skeleton variant="rectangular" height={50} width={500}/>
								</Grid>
							) : (
								<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
									{
										lojas?.length ? (
											<Autocomplete
												noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
												data-testid="select-store"
												disablePortal
												disableClearable
												onChange={(event, newValue) => {
													handleChange(newValue);
												}}
												PaperComponent={({ children }) => (
													<Paper
														style={{
															backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
															color: temaEscuro ? "#FFF" : "#4A5568",
															fontWeight: "500"
														}}
													>
														{children}
													</Paper>
												)}
												options={lojas}
												groupBy={(option) => option.loja_nome}
												sx={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE, width: "50%"}}
												renderInput={(params) =>
													<TextField {...params} 
													label="Loja" 
													InputLabelProps={{
														style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}}}
													/>
												}
												renderGroup={(params) => (
													<li key={params.key}>
														<Typography sx={{p: ".5rem 0 .3rem 1rem", color: TITLE_REUNIAO, fontSize: "18px"}}>{params.group}</Typography>
														<Divider variant="middle" sx={{backgroundColor: BORDER_BUTTON, mb: 1}}/>
														<ul style={{ padding: 0}}>{params.children}</ul>
													</li>
												)}
											/>
										) : (
											<span style={styles.text}>Nenhuma loja encontrada</span>
										)
									}
								</Grid>
							)}
					</Grid>

				</React.Fragment>
			)
			}
		</div>
	);
};

// Create styles
const styles = {
	center: {
		textAlign: "center",
		margin: "auto",
		width: "70%",
		// margin is 30px
		height: "calc(100vh - 60px)",
		display: "flex",
		alignItems: "center"
	},
	text: {
		color: GRAY_LABEL_UX,
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "32px"
	}
};

export default SelectEstabelecimento;