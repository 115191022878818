import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import reducers from "./reducers";
import ReduxThunk from "redux-thunk";
// import * as Sentry from "@sentry/react";

// eslint-disable-next-line no-undef
// if(process.env.NODE_ENV === "production"){
//     Sentry.init({
//         // eslint-disable-next-line no-undef
//         dsn: process.env.REACT_APP_SENTRY_DSN,
//         // Performance Monitoring
//         tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     });
// }

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
registerServiceWorker();
