import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../../axios";
import Loading from "../../components/Loading/Loading";
import InputPassword from "../../components/Input/InputPassword";
import {changeResetarSenha} from "../../actions/AuthActions";
import {showSnackMessage} from "../../actions/SnackActions";
import {styleYellowButton, WHITE_ESCRITA_THEME, BORDER_BUTTON, BLUE_THEME} from "../../shared/utils";


const theme = createTheme();

const ChangePassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();
	const isPerfil = location?.state?.isPerfil;
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [lastPassword, setLastPassword] = useState("");

	const resetarSenha = useSelector(state => state.AuthReducer.resetarSenha);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	useEffect(() => {
		if (!isPerfil) {
			if(!resetarSenha){	
				navigate("/dashboard");
			}
		}
			
	}, [resetarSenha, isPerfil]);

	const handleSubmit = () => {
		const data = {
			password,
			last_password: lastPassword,
			confirm_password: confirmPassword
		};
		setLoading(true);
		api.PutChangePassword(data).then(() => {
			dispatch(showSnackMessage({message: "Senha alterada com sucesso!"}));
			dispatch(changeResetarSenha(false));
			setLoading(false);
			if(isPerfil){
				navigate("/perfil");
			}
		}).catch(() => {
			dispatch(showSnackMessage({message: "A antiga senha não confere!", severity: "error"}));
			setLoading(false);
		});
	};

	const checkForm = () => {
		let error = false;
		if(password !== confirmPassword){
			error = true;
			dispatch(showSnackMessage({message: "A senha e a confirmação não conferem!", severity: "error"}));
		}
		if(password.length < 8){
			error = true;
			dispatch(showSnackMessage({message: "A senha deve conter no mínimo 8 caracteres!", severity: "error"}));
		}
		if(error === false){
			handleSubmit();
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs" data-testid="change-password">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1 }} style={{backgroundColor: temaEscuro && BLUE_THEME}}>
						<LockOutlinedIcon style={{color: temaEscuro && BORDER_BUTTON}}/>
					</Avatar>
					<Typography component="h1" variant="h5" sx={{color: temaEscuro && WHITE_ESCRITA_THEME}}>
                        Alteração de senha
					</Typography>

					<Box component="form" sx={{ mt: 1 }}>
						<InputPassword 
							label="Senha anterior" 
							password={lastPassword} 
							handleChange={(e) => setLastPassword(e.target.value)}
						/>
						<InputPassword 
							label="Senha" 
							password={password} 
							handleChange={(e) => setPassword(e.target.value)}
						/>
						<InputPassword 
							label="Confirmação de Senha" 
							password={confirmPassword}
							handleChange={(e) => setConfirmPassword(e.target.value)}
						/>
						{
							loading ? (
								<Loading />
							) : (
								<Button
									data-testid="submit-button"
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
									style={styleYellowButton}
									onClick={() => checkForm()}
								>
                                    Alterar senha
								</Button>
							)
						}
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default ChangePassword;