import React, {useState, useEffect} from "react";
import toast, { Toaster } from "react-hot-toast";
import {onMessageListener} from "../../firebase";
import notificationSound from "../../assets/sounds/notification.mp3";
import ToastDisplay from "./ToastDisplay";
import {changeNotifications} from "../../actions/AuthActions";
import {useDispatch} from "react-redux";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";

const sound = new Audio(notificationSound);

const Notification = () => {

    const dispatch = useDispatch();
    const [notification, setNotification] = useState({});

    useEffect(() => {
        toast.remove();
        if (Object.keys(notification).length) {
            handleNotification();
            getUserInfo();
        }
    }, [notification]);

    const getUserInfo = async () => {
        api.UserInfo().then((response) => {
            let notificacoes = response.data.notifications;
            dispatch(changeNotifications(notificacoes));
        })
        .catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleNotification = () => {        
        if (notification?.title !== "Sucesso na importação da NF-e") {
            playNotification();
            if (notification?.body === "Atualize a página para visualizar") {
                notify(true);
            }
            else {
                notify();
            }
        }
    };

    const playNotification = () => {
        const playPromise = sound.play();
        if (playPromise !== undefined) {
            playPromise
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(`error when trying to play audio: ${error}`);
                });
        }
    };

    const notify = (isDDA = false) =>  toast(
        (toastConfig) => <ToastDisplay toastConfig={toastConfig} notification={notification} isDDA={isDDA}/>,

        {
            duration: 100000,
            style: {
                maxWidth: 500
            }
        }
    );

    // foreground notifications
    onMessageListener()
        .then((payload) => {
            if(payload?.notification && payload?.notification?.title !== "Sucesso na importação da NF-e") {
                setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
            }
        })
        .catch(() => setNotification({}));

    // background notifications
    navigator?.serviceWorker?.addEventListener("message", function handler(event) {
        const payload = event?.data;        
        if(payload?.notification && payload?.notification?.title !== "Sucesso na importação da NF-e") {
            playNotification();
        }
    });

    return (
        <React.Fragment>
            <Toaster position="top-center" reverseOrder={false} />
        </React.Fragment>

    );
};

export default React.memo(Notification);