import React, {useState, useEffect} from "react";
import {
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    BORDER_TABLE,
    styleTextStrong,
    GRAY_LABEL_UX,
    DASH_KPI_DATA_COLOR
} from "../../shared/utils";
import {useSelector} from "react-redux";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import IntlCurrencyInput from "react-intl-currency-input";
import Grid from "@mui/material/Grid";
import InputsApelidoTipo from "./InputsApelidoTipo";

const FormCaixinha = (props) => {

    const {
        inputTipoConta,
        setInputTipoConta,
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        isBancoEdit,
        handleDayMonthYear,
        inputSaldoFinal,
        setInputSaldoFinal,
        selectedDate

    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [lastDate, setLastDate] = useState("");

    const getLastDate = (date) => {
        const [day, month, year] = date.split("/");
        const lastDate = new Date(`${year}-${month}-${day}`);

        lastDate.setDate(lastDate.getDate() - 1);

        setLastDate(lastDate.toLocaleDateString("pt-BR"));
    };

    useEffect(() => {
        if (contaDetails.data_competencia !== selectedDate && selectedDate !== null){
            getLastDate(selectedDate);
        } else if (selectedDate === null) {
            getLastDate(contaDetails.data_competencia);
        }
    }, [contaDetails.data_competencia, selectedDate]);

    const currencyConfig = {
		locale: "pt-BR",
		formats: {
			number: {
				BRL: {
					style: "currency",
					currency: "BRL",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			},
		},
	};

    return (
        <React.Fragment>
            <InputsApelidoTipo
                inputTipoConta={inputTipoConta}
                isBancoEdit={isBancoEdit}
                contaDetails={contaDetails}
                setInputTipoConta={setInputTipoConta}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
            />
            <FilterDayMonthYear
                handleSubmit={handleDayMonthYear}
                dateCurrent={contaDetails.data_competencia}
                label="Data do saldo"
                width="250px"
                isEditConta
            />
            <Grid container sx={{width: "250px"}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px"}}>Saldo final da conta em {lastDate} </a>
                    <div className="divCurrencyInputNew" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                        <IntlCurrencyInput
                            value={inputSaldoFinal}
                            currency="BRL"
                            config={currencyConfig}
                            onChange={(event, value) => setInputSaldoFinal(value)}
                            className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                            style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                            data-testid="input-faturamento-bruto"
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormCaixinha);