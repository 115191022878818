import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loading from "../../components/Loading/Loading";
import VoxelLogo from "./VoxelLogo.png";
import CopyRight from "../../components/CopyRight/CopyRight";
import Countdown from "react-countdown";
import { GRAY_LABEL_UX, GRAY_HEADER_UX, GREEN_SUCCESS_UX, styleButton, styleYellowButton } from "../../shared/utils";
import "./Login.css";



const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function CheckCode() {
    const navigate = useNavigate();
    const location = useLocation();

    const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [resendCode, setResendCode] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
		setEmail(location.state.email);
	}, []);

	const handleSubmit = () => {
		const data = {
			email,
			code
		};
		setError(false);
		setLoading(true);
		api.GetCheckCode(data).then(() => {
			setLoading(false);
            navigate("/reset_pwd", { state: { email, code }});
		}).catch(() => {
			setError(true);
			setLoading(false);
		});
	};

    const resendVerificationCode = () => {
		const data = {
			email
		};
		setLoading(true);
		api.SendResetPwdEmail(data).then(() => {
			setLoading(false);
            setResendCode(true);
		}).catch(() => {
			setLoading(false);
		});
	};

    const time = React.useMemo(() => {
        return Date.now() + 60000;
    }, [resendCode]);

    const formatTime = (time) => {
        if (time < 10) {
            return `0${time}`;
        }
        return time;
    };

	return (
		<React.Fragment>
            <Grid container spacing={0} sx={{overflow: "hidden", height: "100vh"}}>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8} sx={{display: "flex", alignItems: "center"}}>
                    <div style={styles.center}>
                        <Grid container spacing={0} sx={{paddingTop: 15}}>
                            <Grid item xs={12}>
                                <img src={VoxelLogo} style={styles.logo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={styles.header}>Por favor, digite o código que enviamos para o seu e-mail</p>
                            </Grid>
                            <Grid item xs={12}>
                                <h1 style={styles.text}>O código foi enviado para {email} </h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ mt: 4 }}>
                                    <TextField
                                            data-testid="code-input"
                                            margin="normal"
                                            fullWidth
                                            label="Código de confirmação"
                                            autoComplete="code"
                                            autoFocus
                                            onChange={(e) => setCode(e.target.value)}
                                            value={code}
                                            error={error}
                                    />
                                    {
                                        successMessage && (
                                            <p style={{color: GREEN_SUCCESS_UX, fontWeight: "400", fontSize: "16px"}}>Um novo código foi enviado para o e-mail informado.</p>
                                        ) 
                                    }
                                    {
                                        loading ? (
                                            <Loading />
                                        ) : (
                                            <ThemeProvider theme={theme}>
                                                <Button
                                                    data-testid="submit-button"
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    onClick={() => handleSubmit()}
                                                    style={styleYellowButton}
                                                >
                                                    Recuperar senha 
                                                </Button>
                                            </ThemeProvider>
                                        )
                                    }
                                    { 
                                        (resendCode === true) && (
                                            <Countdown date={time} onComplete={() => {setResendCode(false); setSuccessMessage(false);}} renderer={({ minutes, seconds }) => {
                                                return <span style={{color: "#718096", fontWeight: "400", fontSize: "16px"}}>Aguarde {formatTime(minutes)}:{formatTime(seconds)} segundos para reenviar o código novamente</span>;
                                            }}/>
                                        )
                                    }
                                    {
                                        loading ? (
                                            <Loading />
                                        ) : (
                                            <ThemeProvider theme={theme}>
                                                <Button
                                                    data-testid="resend-button"
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 2, mb: 4 }}
                                                    onClick={() => {resendVerificationCode(); setSuccessMessage(true);}}
                                                    style={styleButton}
                                                    disabled={resendCode}
                                                >
                                                    Reenviar código
                                                </Button>
                                            </ThemeProvider>
                                        )
                                    }
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <LinkRouter data-testid="redirect-back-login" to='/' variant="body2" style={{textDecoration: "none", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: "17px", display: "flex", justifyContent: "center"}}>
                                                Voltar ao login
                                            </LinkRouter>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <div style={styles.copyright}>
                            <CopyRight color="black"/>
                        </div>
                    </div>
                </Grid>
                <Box
                    component={Grid}
                    item
                    xs={0} 
                    sm={0}
                    md={5}
                    lg={4}
                    xl={4}
                    display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}
                >
                </Box>
            </Grid>
		</React.Fragment>
	);
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
	logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
	},
    header: {
        width: "100%",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX
    },
    text: {
        width: "600px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAllign: "left center",
        marginTop: "100px",
    },
    button: {
        width: "54px",
        height: "28px",
        fontStyle: "normal",
        fontWeight: "600",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px"
    }
};