import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BLUE_THEME, GRAY_BG_UX, BORDER_BUTTON } from "../../shared/utils";
import { useSelector } from "react-redux";


const InputNumber = (props) => {
    const { value, setValue, onBlur, error } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (_value) => {
        // Check if the value is a valid integer (positive or negative)
        if (/^-?\d*\.?\d*$/.test(_value) || _value === "") {
            setInputValue(_value);
        }
    };

    const handleBlur = () => {
        setValue(inputValue);
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <Box>
            <TextField
                label={props?.label}
                id="input-number-textfield"
                className={props.className}
                variant="outlined"
                value={inputValue}
                onChange={(event) => {
                    handleChange(event.target.value);
                }}
                onBlur={() => handleBlur()}
                sx={{
                    width: props?.width || "250px", padding: props?.padding,
                    ...(!props?.disabled && {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX})
                }}
                size="small"
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px"},
                }}
                disabled={props?.disabled}
                error={error}
            />
        </Box>
    );
};

export default InputNumber;