import React, {useEffect, useState} from "react";
import Skeleton from "@mui/material/Skeleton";
import Header from "../../components/Titles/Header";
import {useDispatch, useSelector} from "react-redux";
import {changeStore} from "../../actions/StoreActions";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, BORDER_TABLE, formatterPercentage, formatterThousand, GRAY_BG_UX, GRAY_LABEL_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle, styleText, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, YELLOW_BG_UX, WHITE_TABLE, ROW_MAIN, getCurrentDate
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputSearch from "../../components/Input/InputSearch";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import InputNumber from "../../components/Input/InputNumber";
import api from "../../axios";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import YellowButton from "../../components/Buttons/YellowButton";
import GrayButton from "../../components/Buttons/GrayButton";
import ParcelasModal from "../../components/Modal/ParcelasModal";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import moment from "moment";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import {showSnackMessage} from "../../actions/SnackActions";


const GerarCompra = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [fornecedoresOptions, setFornecedoresOptions] = useState([]);
    const [produtoOptions, setProdutoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);

    const formaRecorrenciaOptions = ["Mês/meses", "Semana/semanas"];
    const recorrenciaVencimentoOptions = ["Em um período específico", "Indeterminado", "Após algumas ocorrências"];

    const parcelaOptions = ["1", "2", "3", "4", "5", "12"];
    const formaPagamentoOptions = ["Cartão de crédito", "Dinheiro", "Pix"];
	const currentMonthDate = moment(getCurrentDate()).toDate();

    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedDataCompra, setSelectedDataCompra] = useState(currentMonthDate);
    const [repetirCompra, setRepetirCompra] = useState(false);
    const [qtdRecorrenciaCompra, setQtdRecorrenciaCompra] = useState(null);
    const [formaRecorrenciaCompra, setFormaRecorrenciaCompra] = useState(null);
    const [selectedDataCompraRecorrencia, setSelectedDataCompraRecorrencia] = useState(currentMonthDate);
    const [selectedRecorrenciaVencimento, setSelectedRecorrenciaVencimento] = useState(null);
    const [selectedDataRecorrenciaTermino, setSelectedDataRecorrenciaTermino] = useState(currentMonthDate);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [descontoValue, setDescontoValue] = useState(null);
    const [freteValue, setFreteValue] = useState(null);
    const [totalDiscountedValue, setTotalDiscountedValue] = useState(0);
    const [selectedParcela, setSelectedParcela] = useState(null);
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [parcelaDataVencimento, setParcelaDataVencimento] = useState(currentMonthDate);
    const [rows, setRows] = useState([
        {
            produto_servico: "",
            detalhes_item: "",
            quantidade: 1,
            valor_unitario: null,
            total: null
        },
    ]);
    const [observacoes, setObservacoes] = useState("");

    const [selectedReaisPercent, setSelectedReaisPercent] = useState("reais");
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [inputError, setInputError] = useState(false);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setLocationId(parseInt(query.get("estabelecimento_id")));
        if (locationId !== 0 && locationId != null) {
            dispatch(changeStore(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        calcTotalValue();
    }, [rows]);

    useEffect(() => {
        calcTotalDiscountedValue();
    }, [totalRows, descontoValue, freteValue, selectedReaisPercent]);

    useEffect(() => {
        if (selectedParcela) {
            handleParcelas();
        }
    }, [selectedParcela, totalDiscountedValue]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId
            };
            api.GetFornecedores(dataRequest).then(response => {
                setFornecedoresOptions(response.data);
            });
            api.GetCategorias(dataRequest).then(response => {
                setCategoriaOptions(response.data);
            });
            api.GetProdutosServicos(dataRequest).then(response => {
                setProdutoOptions(response.data);
            });
            setLoading(false);
        }
    };

    const postSaveCompra = () => {
        if (!selectedFornecedor || !selectedCategoria || !selectedDataCompra || !selectedParcela || !selectedFormaPagamento) {
            setInputError(true);
            return;
        }
        for (let parcela of parcelas) {
            parcela.data_vencimento = moment(parcela.data_vencimento).format("DD-MM-YYYY");
        }
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            fornecedor_id: selectedFornecedor.value,
            categoria: selectedCategoria.value,
            data_compra: moment(selectedDataCompra).format("DD-MM-YYYY"),
            rows: rows,
            reais_percent: selectedReaisPercent,
            desconto: parseFloat(descontoValue) || 0,
            frete: parseFloat(freteValue) || 0,
            total: totalDiscountedValue,
            forma_pagamento: selectedFormaPagamento,
            condicao_pagamento: selectedParcela,
            vencimento: moment(parcelaDataVencimento).format("DD-MM-YYYY"),
            parcelas: parcelas,
        };
        if (codigoReferencia) {
            dataRequest.codigo_referencia = codigoReferencia;
        }
        if (observacoes) {
            dataRequest.observacoes = observacoes;
        }
        if (repetirCompra) {
            dataRequest.recorrencia = {
                qtd_recorrencia: qtdRecorrenciaCompra,
                forma_recorrencia: formaRecorrenciaCompra,
                data_compra_recorrencia: selectedDataCompraRecorrencia,
                vencimento_recorrencia: selectedRecorrenciaVencimento,
                data_termino_recorrencia: selectedDataRecorrenciaTermino
            };
        }
        api.PostGerarCompra(dataRequest).then(() => {
            dispatch(showSnackMessage({message: "Compra gerada com sucesso!", severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}-${mes}-${ano}`);
    };

    const handleRepetirCompra = (event) => {
        setRepetirCompra(event.target.checked);
    };

    const handleParcelas = (skipIndex = false, skipRow = {}, alteredField = "") => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        let _totalValue = totalDiscountedValue;
        let parcelasQuantidade = selectedParcela;
        for (let i = 0; i < selectedParcela; i++) {
            if ((skipIndex || typeof skipIndex === "number") && i === skipIndex) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= totalDiscountedValue || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = totalDiscountedValue - skipRow.valor;
                    skipRow.percentual = parseFloat(formatterPercentage((skipRow.valor * 100) / totalDiscountedValue, 2));
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(formatterThousand(totalDiscountedValue * (skipRow.percentual / 100), true));
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = selectedParcela - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === selectedParcela - 1) {
                valorParcela = formatterThousand(_totalValue - valorTotal, true);
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(_totalValue / parseFloat(parcelasQuantidade), true);
                percentParcela = formatterPercentage((valorParcela * 100) / totalDiscountedValue, 2);
            }
            let parcelaData = moment(parcelaDataVencimento || moment(getCurrentDate()).toDate()).add(i, "months");
            _parcelas.push({
                "valor": parseFloat(valorParcela),
                "percentual": parseFloat(percentParcela),
                "data_vencimento": parcelaData.toDate(),
                "descricao": ""
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const handleRemoveRow = (idx) => {
        rows.splice(idx, 1);
        setRows([...rows]);
    };

    const calcTotalValue = () => {
        let total = 0;
        rows.map((row) => {
            total += row.total;
        });
        setTotalRows(total);
    };

    const calcTotalDiscountedValue = () => {
        if (descontoValue || freteValue) {
            if (selectedReaisPercent === "percent") {
                setTotalDiscountedValue(totalRows - (totalRows * (parseFloat(descontoValue || 0) / 100)) + parseFloat(freteValue || 0));
            } else {
                setTotalDiscountedValue(totalRows - parseFloat(descontoValue || 0) + parseFloat(freteValue || 0));
            }
        } else {
            setTotalDiscountedValue(totalRows);
        }
    };

    return (
        <React.Fragment>
            <div className="main" data-testid="gerar-compra">
                <ParcelasModal
                    openModal={openParcelasModal}
                    setOpenModal={setOpenParcelasModal}
                    formaPagamento={selectedFormaPagamento}
                    valorPagamento={totalDiscountedValue}
                    condicaoPagamento={selectedParcela}
                    parcelas={parcelas}
                    setParcelas={setParcelas}
                    handleParcelas={handleParcelas}
                />
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={getInputsOptions}
                />
                {
                    loading && (
                        <Skeleton sx={{mb: "14px"}} variant="rectangular" width={"17%"} height={"17px"}/>
                    )
                }
                <Header pathTitle="Notas de Compras" title="Gerar Compra"/>
                {
                    (estabelecimentoId === 0 || (locationId === 0 || locationId == null)) ? (
                        <SelectEstabelecimento/>
                    ) : (
                        <React.Fragment>
                            {
                                loading ? (
                                    <Skeleton variant="rectangular" width={"100%"} height={"80vh"}/>
                                ) : (
                                    <React.Fragment>
                                        <Paper sx={{
                                            ...paperStyle,
                                            backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                                            backgroundImage: temaEscuro && "none",
                                            border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <a>Informações da compra</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{
                                                    border: 0,
                                                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                    mb: 2.5,
                                                    ml: 2
                                                }}/>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                filterOptions={fornecedoresOptions}
                                                                selectedOption={selectedFornecedor}
                                                                setSelectedOption={setSelectedFornecedor}
                                                                placeholder="Fornecedor *"
                                                                canCreate
                                                                setOpenCreateModal={setOpenCreateModal}
                                                                setOptionCreateModal={setOptionCreateModal}
                                                                inputError={inputError}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InputSearch
                                                                functionProps={() => {}}
                                                                setSearchValue={setCodigoReferencia}
                                                                searchValue={codigoReferencia}
                                                                className="input-text"
                                                                label={"Código de referência"}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                filterOptions={categoriaOptions}
                                                                selectedOption={selectedCategoria}
                                                                setSelectedOption={setSelectedCategoria}
                                                                placeholder="Categoria de despesa *"
                                                                canCreate
                                                                setOpenCreateModal={setOpenCreateModal}
                                                                setOptionCreateModal={setOptionCreateModal}
                                                                inputError={inputError}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterDayMonthYear
                                                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                handleSubmit={handleDayMonthYear}
                                                                value={selectedDataCompra}
                                                                setState={setSelectedDataCompra}
                                                                size={"small"}
                                                                width={"250px"}
                                                                iconPosition={"end"}
                                                                label={"Data da compra"}
                                                                inputError={inputError}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Checkbox
                                                                checked={repetirCompra}
                                                                icon={<CircleOutlinedIcon/>}
                                                                checkedIcon={<CircleIcon/>}
                                                                onChange={handleRepetirCompra}
                                                            />
                                                            <a style={{
                                                                ...styleText,
                                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                            }}>Repetir compra</a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    repetirCompra && (
                                                        <React.Fragment>
                                                            <Grid item xs={12}>
                                                                <a>Configurações da recorrência</a>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{
                                                                border: 0,
                                                                borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                                mb: 2.5,
                                                                ml: 2
                                                            }}/>
                                                            <Grid item>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <InputNumber
                                                                            value={qtdRecorrenciaCompra}
                                                                            setValue={setQtdRecorrenciaCompra}
                                                                            label="Repetir compra a cada"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FilterSingleOption
                                                                            filterOptions={formaRecorrenciaOptions}
                                                                            selectedOption={formaRecorrenciaCompra}
                                                                            setSelectedOption={setFormaRecorrenciaCompra}
                                                                            placeholder="Forma de recorrência"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FilterDayMonthYear
                                                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                            handleSubmit={handleDayMonthYear}
                                                                            value={selectedDataCompraRecorrencia}
                                                                            setState={setSelectedDataCompraRecorrencia}
                                                                            size={"small"}
                                                                            width={"250px"}
                                                                            iconPosition={"end"}
                                                                            label={"Data de criação da compra"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FilterSingleOption
                                                                            filterOptions={recorrenciaVencimentoOptions}
                                                                            selectedOption={selectedRecorrenciaVencimento}
                                                                            setSelectedOption={setSelectedRecorrenciaVencimento}
                                                                            placeholder={"Vencimento"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FilterSingleOption
                                                                            filterOptions={recorrenciaVencimentoOptions}
                                                                            selectedOption={selectedRecorrenciaVencimento}
                                                                            setSelectedOption={setSelectedRecorrenciaVencimento}
                                                                            placeholder={"Término da recorrência"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FilterDayMonthYear
                                                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                            handleSubmit={handleDayMonthYear}
                                                                            value={selectedDataRecorrenciaTermino}
                                                                            setState={setSelectedDataRecorrenciaTermino}
                                                                            size={"small"}
                                                                            width={"250px"}
                                                                            iconPosition={"end"}
                                                                            label={"Data de término"}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
                                                }
                                                <Grid item xs={12}>
                                                    <a>Itens da compra</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{
                                                    border: 0,
                                                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                    mb: 2.5,
                                                    ml: 2
                                                }}/>
                                                <Grid item xs={12}>
                                                    <TableContainer className={"table-container"}>
                                                        <Table>
                                                            <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}}>
                                                                <TableRow>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 5}}}
                                                                    >
                                                                        Produto/Serviço *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 5}}}
                                                                    >
                                                                        Detalhes do item
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 5}}}
                                                                    >
                                                                        Quantidade *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 5}}}
                                                                    >
                                                                        Valor unitário *
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={"center"}
                                                                        style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 5}}}
                                                                    >
                                                                        Total *
                                                                    </TableCell>
                                                                    {
                                                                        rows.length > 1 && (
                                                                            <TableCell/>
                                                                        )
                                                                    }
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    rows.map((row, idx) => {
                                                                        return (
                                                                            <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                                                <TableCell
                                                                                    align={"center"}
                                                                                >
                                                                                    <FilterSingleOption
                                                                                        filterOptions={produtoOptions}
                                                                                        selectedOption={row.produto_servico}
                                                                                        setSelectedOption={(value) => (setRows(rows.map((row_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                row_aux.produto_servico = value;
                                                                                            }
                                                                                            return row_aux;
                                                                                        })))}
                                                                                        placeholder="Produto/Serviço *"
                                                                                        width={"375px"}
                                                                                        canCreate
                                                                                        setOpenCreateModal={setOpenCreateModal}
                                                                                        setOptionCreateModal={setOptionCreateModal}
                                                                                        inputError={inputError}
                                                                                        disableClearable
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align={"center"}
                                                                                >
                                                                                    <InputSearch
                                                                                        functionProps={() => {}}
                                                                                        setSearchValue={(value) => (setRows(rows.map((row_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                row_aux.detalhes_item = value;
                                                                                            }
                                                                                            return row_aux;
                                                                                        })))}
                                                                                        searchValue={row.detalhes_item}
                                                                                        className="input-text"
                                                                                        padding={0.5}
                                                                                        width={"200px"}
                                                                                        label={"Detalhes"}
                                                                                    />
                                                                                    </TableCell>
                                                                                <TableCell
                                                                                    align={"center"}
                                                                                >
                                                                                    <InputNumber
                                                                                        value={row.quantidade}
                                                                                        setValue={(value) => (setRows(rows.map((row_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                row_aux.quantidade = parseFloat(value);
                                                                                            }
                                                                                            if (row.valor_unitario !== 0) {
                                                                                                row_aux.total = row_aux.valor_unitario * row_aux.quantidade;
                                                                                            }
                                                                                            return row_aux;
                                                                                        })))}
                                                                                        width={"100px"}
                                                                                        padding={0.5}
                                                                                        error={inputError}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align={"center"}
                                                                                >
                                                                                    <InputNumber
                                                                                        value={row.valor_unitario}
                                                                                        setValue={(value) => (setRows(rows.map((row_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                row_aux.valor_unitario = parseFloat(value);
                                                                                            }
                                                                                            if (row.quantidade !== 0) {
                                                                                                row_aux.total = row_aux.valor_unitario * row_aux.quantidade;
                                                                                            }
                                                                                            return row_aux;
                                                                                        })))}
                                                                                        width={"100px"}
                                                                                        padding={0.5}
                                                                                        error={inputError}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align={"center"}
                                                                                >
                                                                                    <InputNumber
                                                                                        value={row.total}
                                                                                        setValue={(value) => (setRows(rows.map((row_aux, idx_aux) => {
                                                                                            if (idx_aux === idx) {
                                                                                                row_aux.total = parseFloat(value);
                                                                                            }
                                                                                            return row_aux;
                                                                                        })))}
                                                                                        width={"125px"}
                                                                                        padding={0.5}
                                                                                        error={inputError}
                                                                                    />
                                                                                </TableCell>
                                                                                {
                                                                                    rows.length > 1 && (
                                                                                        <TableCell
                                                                                            align={"center"}
                                                                                            style={{padding: 0, cursor: "pointer"}}
                                                                                            onClick={() => handleRemoveRow(idx)}
                                                                                        >
                                                                                            <a>X</a>
                                                                                        </TableCell>
                                                                                    )
                                                                                }
                                                                            </TableRow>
                                                                        );
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <GrayButton
                                                        title={"+ Adicionar nova linha"}
                                                        size={"small"}
                                                        onClick={() => {
                                                            setRows([...rows, {
                                                                produto_servico: "",
                                                                detalhes_item: "",
                                                                quantidade: 1,
                                                                valor_unitario: null,
                                                                total: null
                                                            }]);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Grid container spacing={2}
                                                                  sx={{display: "flex", justifyContent: "end"}}>
                                                                <Grid item>
                                                                    <ButtonGroup variant="contained"
                                                                                 aria-label="Basic button group">
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={selectedReaisPercent === "reais" ? styles.selectedButton : styles.unselectedButton}
                                                                            onClick={() => setSelectedReaisPercent("reais")}
                                                                        >
                                                                            R$
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={selectedReaisPercent === "percent" ? styles.selectedButton : styles.unselectedButton}
                                                                            onClick={() => setSelectedReaisPercent("percent")}
                                                                        >
                                                                            %
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </Grid>
                                                                <Grid item>
                                                                    <InputNumber
                                                                        label={"Desconto"}
                                                                        value={descontoValue}
                                                                        setValue={setDescontoValue}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{
                                                            border: 0,
                                                            borderRight: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                            ml: 2.5,
                                                            mr: 2.5
                                                        }}/>
                                                        <Grid item>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <InputNumber
                                                                        label={"Frete"}
                                                                        value={freteValue}
                                                                        setValue={setFreteValue}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        ...paperStyle,
                                                        backgroundColor: temaEscuro && BLUE_THEME,
                                                        borderRadius: 2
                                                    }}>
                                                        <Grid container spacing={0.5}>
                                                            <Grid item xs={3}>
                                                                <a>Total da compra</a>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                        sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Total dos produtos (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                        sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(totalRows)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>-</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Descontos ({selectedReaisPercent === "reais" ? "R$" : "%"})</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(descontoValue)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>+</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Frete ({selectedReaisPercent === "reais" ? "R$" : "%"})</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(freteValue)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ml: 3.5}}>
                                                                <b>=</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>Total (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                                        <a>{formatterThousand(totalDiscountedValue)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a>Informações de pagamento</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{
                                                    border: 0,
                                                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                    mb: 2.5,
                                                    ml: 2
                                                }}/>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                filterOptions={formaPagamentoOptions}
                                                                selectedOption={selectedFormaPagamento}
                                                                setSelectedOption={setSelectedFormaPagamento}
                                                                placeholder="Forma de pagamento"
                                                                disabled={repetirCompra}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InputNumber
                                                                label={"Valor a pagar (R$)"}
                                                                value={totalDiscountedValue}
                                                                setValue={null}
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                filterOptions={parcelaOptions}
                                                                selectedOption={selectedParcela}
                                                                setSelectedOption={setSelectedParcela}
                                                                placeholder="Condição de pagamento"
                                                                disabled={repetirCompra}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterDayMonthYear
                                                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                handleSubmit={handleDayMonthYear}
                                                                size={"small"}
                                                                width={"250px"}
                                                                iconPosition={"end"}
                                                                label={"Vencimento"}
                                                                setState={setParcelaDataVencimento}
                                                                value={parcelaDataVencimento}
                                                                disabled={repetirCompra}
                                                                inputError={inputError}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <GrayButton
                                                                title={"Editar parcelas"}
                                                                onClick={() => setOpenParcelasModal(true)}
                                                                disabled={selectedParcela <= 1 || repetirCompra}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a>Observações complementares</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{
                                                    border: 0,
                                                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                    mb: 2.5,
                                                    ml: 2
                                                }}/>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label={"Observações"}
                                                        id="input-observacoes-textfield"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        maxRows={6}
                                                        value={observacoes}
                                                        onChange={(event) => {
                                                            setObservacoes(event.target.value);
                                                        }}
                                                        sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}
                                                          sx={{display: "flex", justifyContent: "end"}}>
                                                        <Grid item>
                                                            <GrayButton
                                                                title={"Voltar"}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <YellowButton
                                                                title={"Salvar"}
                                                                onClick={() => postSaveCompra()}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    );
};

export default GerarCompra;

const styles = {
    selectedButton: {
        backgroundColor: YELLOW_BG_UX,
        color: BLUE_THEME,
        "&.MuiButtonBase-root:hover": {
            bgcolor: YELLOW_BG_UX
        }
    },
    unselectedButton: {
        backgroundColor: BLUE_THEME,
        color: YELLOW_BG_UX,
        "&.MuiButtonBase-root:hover": {
            bgcolor: YELLOW_BG_UX,
            color: BLUE_THEME
        }
    }
};