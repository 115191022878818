import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function CopyRight(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{fontSize: "12px"}}>
            {"Copyright © "}
            <Link style={{textDecoration: "none"}} color="inherit" href="https://voxelgestao.com/">
                VX - Menu Financeiro
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}