import React, {useState, useEffect} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    BORDER_TABLE,
    styleTextStrong,
    GRAY_LABEL_UX,
    DASH_KPI_DATA_COLOR
} from "../../shared/utils";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import IntlCurrencyInput from "react-intl-currency-input";
import Grid from "@mui/material/Grid";
import InputsApelidoTipo from "./InputsApelidoTipo";

const FormPoupanca = (props) => {

    const {
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        handleDayMonthYear,
        inputSaldoInicial,
        setInputSaldoInicial,
        selectedDate,
        inputModalidade,
        setInputModalidade,
        modalidades,
        bancos,
        setInputBanco,
        inputBanco,
        setInputContaVinculada,
        inputContaVinculada,
        contasCorrente
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [lastDate, setLastDate] = useState("");

    const getLastDate = (date) => {
        const [day, month, year] = date.split("/");
        const lastDate = new Date(`${year}-${month}-${day}`);

        lastDate.setDate(lastDate.getDate() - 1);

        setLastDate(lastDate.toLocaleDateString("pt-BR"));
    };

    useEffect(() => {
        if (contaDetails.data_competencia !== selectedDate && selectedDate !== null){
            getLastDate(selectedDate);
        } else if (selectedDate === null) {
            getLastDate(contaDetails.data_competencia);
        }
    }, [contaDetails.data_competencia, selectedDate]);

    const currencyConfig = {
		locale: "pt-BR",
		formats: {
			number: {
				BRL: {
					style: "currency",
					currency: "BRL",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			},
		},
	};

    return (
        <React.Fragment>
            <InputsApelidoTipo
                contaDetails={contaDetails}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
                disabledTipo
            />
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Banco</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Banco"
                    value={inputBanco}
                    defaultValue={contaDetails.banco}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputBanco(e.target.value)}
                >
                    {bancos.map((banco) => {
                        return (
                            <MenuItem data-testid="menu-item" key={banco} value={banco}>
                                {banco}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Conta Corrente vinculada</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Conta Corrente vinculada"
                    value={inputContaVinculada}
                    defaultValue={contaDetails.cartao_conta_pagamento}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputContaVinculada(e.target.value)}
                >
                    {contasCorrente.map((cv) => {
                        return (
                            <MenuItem data-testid="menu-item" key={cv} value={cv}>
                                {cv}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Modalidade</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputModalidade}
                    label="Banco"
                    defaultValue={contaDetails.modalidade}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputModalidade(e.target.value)}
                >
                        {modalidades.map((mod) => {
                        return (
                            <MenuItem data-testid="menu-item" key={mod} value={mod}>
                                {mod}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FilterDayMonthYear
                handleSubmit={handleDayMonthYear}
                dateCurrent={contaDetails.data_competencia}
                label="Data do saldo"
                width="250px"
                isEditConta
            />
            <Grid container sx={{width: "250px"}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px"}}>Saldo inicial da conta em {lastDate} </a>
                    <div className="divCurrencyInputNew" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                        <IntlCurrencyInput
                            value={inputSaldoInicial}
                            currency="BRL"
                            config={currencyConfig}
                            onChange={(event, value) => setInputSaldoInicial(value)}
                            className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                            style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                            data-testid="input-faturamento-bruto"
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormPoupanca);