import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SavingsIcon from "@mui/icons-material/Savings";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HelpIcon from "@mui/icons-material/Help";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    VERY_WEAK_YELLOW_COMPARATIVO,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BLACK_SUBITEM_NAVBAR,
    BORDER_PAPER,
    YELLOW_BG_UX
} from "../../../shared/utils";
import { useSelector } from "react-redux";


const SelectedTipoConta = (props) => {

    const {setSelectedCard, handleNext} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleCardClick = (cardName) => {
        setSelectedCard(cardName);
        handleNext();
    };

    const getCardStyles = () => ({
        ...styles.cards,
        color: temaEscuro ? VERY_WEAK_YELLOW_COMPARATIVO : YELLOW_BG_UX,
        backgroundColor: temaEscuro && BLUE_THEME,
        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
        "&:hover": {
            backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_ESCRITA_THEME,
            transition: "all 0.3s ease-in-out",
        }
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 700}}>Selecione o tipo de conta que você quer cadastrar</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Contas bancárias e contas de movimentação</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <Box 
                    sx={getCardStyles("Conta Corrente")}
                    onClick={() => handleCardClick("Conta Corrente")}  // Altera o background e salva o nome do card
                >
                    <AccountBalanceIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Conta Corrente</span>
                </Box>
                <Box 
                    sx={getCardStyles("Caixinha")}
                    onClick={() => handleCardClick("Caixinha")}
                >
                    <Inventory2Icon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Caixinha</span>
                </Box>
                <Box 
                    sx={getCardStyles("Cartão de Crédito")}
                    onClick={() => handleCardClick("Cartão de Crédito")}
                >
                    <CreditCardIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Cartão de Crédito</span>
                </Box>
                <Box 
                    sx={getCardStyles("Poupança")}
                    onClick={() => handleCardClick("Poupança")}
                >
                    <SavingsIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Poupança</span>
                </Box>
                <Box 
                    sx={getCardStyles("Investimento")}
                    onClick={() => handleCardClick("Investimento")}
                >
                    <QueryStatsIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Investimento</span>
                </Box>
                <Box 
                    sx={getCardStyles("Aplicação Automática")}
                    onClick={() => handleCardClick("Aplicação Automática")}
                >
                    <SwapVerticalCircleIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Aplicação Automática</span>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Outros tipos de conta</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2}}>
                <Box 
                    sx={getCardStyles("Outro tipo de conta")}
                    onClick={() => handleCardClick("Outro tipo de conta")}
                >
                    <HelpIcon sx={{...styles.cardsIcon}} />
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 500, fontSize: "14px"}}>Outro tipo de conta</span>
                </Box>
            </Grid>
        </Grid>
    );
};

export default React.memo(SelectedTipoConta);

const styles = {
    cards: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 2,
        borderRadius: 2,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        width: "130px"
    },
    cardsIcon: {
        fontSize: "50px"
    },
};