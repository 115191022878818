import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import {getRoutes} from "./Routes";
import ReactGA from "react-ga4";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Notification from "./components/Notification/Notification";
import SnackBar from "./components/SnackBar/SnackBar";

// eslint-disable-next-line no-undef
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

try{
	// eslint-disable-next-line no-undef
	ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}catch (e){
	// eslint-disable-next-line no-console
	console.error(`not able to initialize ReactGA ${e}`);
}

export default function App(){
	const dispatch = useDispatch();
	const token = Cookies.get("tk");
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const resetarSenha = useSelector(state => state.AuthReducer.resetarSenha);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isCommonUser = useSelector(state => state.AuthReducer.isCommonUser);
	const isOperator = useSelector(state => state.AuthReducer.isOperator);
	const isApprover = useSelector(state => state.AuthReducer.isApprover);

	const theme = createTheme({
		palette:{
			mode: temaEscuro ? "dark" : "light"
		},
		typography: {
			allVariants: {
				fontFamily: "Inter, sans-serif",
				color: temaEscuro ? "#FFF" : "#4A5568",
				fontWeight: "500"
			},
		},
	});

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ThemeProvider theme={theme}>
				<Notification />
				<SnackBar>
					<RouterProvider router={getRoutes(dispatch, token, isAdmin, resetarSenha, isCommonUser, isOperator, isApprover)}/>
				</SnackBar>
			</ThemeProvider>
		</LocalizationProvider>
	);
}
