import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    formatterDayMonthYear,
    GRAY_LABEL_UX,
    formatterCurrency,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    BORDER_BUTTON,
    RED_INFO,
    WHITE_TABLE
} from "../../shared/utils";
import Skeleton from "@mui/material/Skeleton";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import {showSnackMessage} from "../../actions/SnackActions";
import { Grid } from "@mui/material";
import FilterEXM from "../../components/Filter/FilterEXM";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import InputSearch from "../../components/Input/InputSearch.js";
import OptionsMenuEXM from "../../components/Buttons/OptionsMenuEXM.js";
import OptionsNovaEXM from "../../components/Buttons/OptionsNovaEXM.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EXMKPI from "../../components/Dash/EXMKPI";
import EXMFooter from "../../components/Dash/EXMFooter";
import DataGridWithPagination from "../../components/DataGrid/DataGridWithPagination";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import api from "../../axios";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import GrayButton from "../../components/Buttons/GrayButton.js";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";


const EXM = () => {

    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const [loading, setLoading] = useState(false);
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [periodo, setPeriodo] = useState("este_mes");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [lastSearchedValue, setLastSearchedValue] = useState("");
    const [searchValue, setSearchValue] = useState(lastSearchedValue || null);
    const [previousContas, setPreviousContas] = useState([]);
    const [contas, setContas] = useState([]);
    const [contasSelected, setContasSelected] = useState();
    const [selectedItem, setSelectedItem] = useState([]);
    const [moreFilters, setMoreFilters] = useState(false);
    const [inputCategoria, setInputCategoria] = useState("");
    const [categorias,  setCategorias] = useState([]);
    const [inputFornecedor, setInputFornecedor] = useState("");
    const [fornecedores, setFornecedores] = useState([]);
    const [tiposMovimentacoes, setTiposMovimentacoes] = useState([]);
    const [inputTipoMovimentacao, setInputTipoMovimentacao] = useState("");
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);

    const [extratosKPIs, setExtratosKPIs] = useState({});
    const [receitaEmAbertoKPI, setReceitaEmAbertoKPI] = useState(0);
    const [receitaRealizadaKPI, setReceitaRealizadaKPI] = useState(0);
    const [despesaEmAbertoKPI, setDespesaEmAbertoKPI] = useState(0);
    const [despesaRealizadaKPI, setDespesaRealizadaKPI] = useState(0);
    const [totalPeriodoKPI, setTotalPeriodoKPI] = useState(0);

    const [selectedKPI, setSelectedKPI] = useState("totalPeriodo");

    const [tableData, setTableData] = useState([]);
    const [footerPeriodo, setFooterPeriodo] = useState("");

    useEffect(() => {
        if (estabelecimentoId !== 0 && periodo !== "custom_periodo") {
            getExtratoMovimentacoes();
        }
    }, [estabelecimentoId, arrowCounter, periodo]);

    const getExtratoMovimentacoes = (clear) => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            periodo: periodo,
            plus_minus: arrowCounter,
            search: clear ? null : searchValue,
            contas: clear ? [] : contasSelected,
            tipo_movimentacao: clear ? "" : inputTipoMovimentacao,
            fornecedor: clear ? "" : inputFornecedor,
            categoria: clear ? "" : inputCategoria,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetExtratoMovimentacoes(dataRequest).then(response => {
            setLoading(false);
            let data = response.data;
            let kpiData = data.kpis;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setLastSearchedValue(data?.searched_value || "");
            let extratoData = data.extrato;
            if (extratoData.extrato.length === 0) {
                setTableData([]);
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
                return;
            }

            setReceitaEmAbertoKPI(kpiData.total_receita_em_aberto);
            setReceitaRealizadaKPI(kpiData.total_receita_realizada);
            setDespesaEmAbertoKPI(kpiData.total_despesa_em_aberto);
            setDespesaRealizadaKPI(kpiData.total_despesa_realizada);
            setTotalPeriodoKPI(kpiData.total);

            setExtratosKPIs(extratoData);
            setTableData(data.extrato.extrato);

            setContas(data.contas);
            setPreviousContas(data.contas_previuos);
            setCategorias(data.categorias);
            setFornecedores(data.fornecedores);
            setTiposMovimentacoes(data.tipos_movimentacao);

            let _footerPeriodo = `${extratoData.extrato[0].data_movimentacao} - ${extratoData.extrato[extratoData.extrato.length - 1].data_movimentacao}`;
            setFooterPeriodo(_footerPeriodo);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const handleClickKPIs = (kpi) => {
        if (kpi === "receitaEmAberto") {
            setTableData(extratosKPIs.receitas_em_aberto);
            setSelectedKPI("receitaEmAberto");
        } else if (kpi === "receitaRealizada") {
            setTableData(extratosKPIs.receitas_realizadas);
            setSelectedKPI("receitaRealizada");
        } else if (kpi === "despesaEmAberto") {
            setTableData(extratosKPIs.despesa_em_aberto);
            setSelectedKPI("despesaEmAberto");
        } else if (kpi === "despesaRealizada") {
            setTableData(extratosKPIs.despesa_realizada);
            setSelectedKPI("despesaRealizada");
        } else if (kpi === "totalPeriodo") {
            setTableData(extratosKPIs.extrato);
            setSelectedKPI("totalPeriodo");
        }
    };

    const columns = [
        {
            field: "data_movimentacao",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: params => formatterDayMonthYear(params)
        },
        { 
            field: "categoria__nome",
            headerName: "Categoria",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{backgroundColor: GRAY_LABEL_UX, padding: 8, borderRadius: "3px", color: temaEscuro ? LINE_TABLE : WHITE_TABLE }}>
                        {params.value}
                    </span>
                );
            }
        },
        { field: "descricao", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        { field: "situacao", headerName: "Situação", flex: 1, align: "center", headerAlign: "center" },
        { 
            field: "valor_total",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (row, value) => {
                if (value.parcela_valor !== null) {
                    return formatterCurrency(value.parcela_valor , 2);
                } else {
                    return formatterCurrency(row, 2);
                }
            },
        },
        { 
            field: "anexo",
            headerName: "Anexo",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: () => {
                return (
                    <AttachFileIcon sx={{color: YELLOW_BG_UX, height: "100%", transform: "rotate(45deg)"}} />
                );
            }
        },
        { 
            field: "saldo",
            headerName: "Saldo",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2),
        },
    ];

    const clearAllFilter = () => {
        setPeriodo("este_mes");
        setContasSelected([]);
        setSearchValue(null);
        setLastSearchedValue("");
        setInputCategoria("");
        setInputFornecedor("");
        setInputTipoMovimentacao("");
        getExtratoMovimentacoes(true);
    };

    const openMoreFilters = () => {
        setMoreFilters(!moreFilters);
    };

    return (
        <div className="main" data-testid="exm-container">
            <Header title="EXM - Extrato de Movimentações" dataTestId="header-exm" />
            <Paper
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none", 
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}
                data-testid="perfil-screen"
            >
                {loading ? (
                    [...Array(15).keys()].map((i,d) =>{
                        return(
                            <Skeleton key={d} height={50}/>
                        );
                    })
                ) : ( 
                    estabelecimentoId === 0 ? (
                        <SelectEstabelecimento/>
                    ) : (
                        <Grid container data-testid="extratoMovimentacoes" sx={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={12}>
                                <Grid container sx={{display: "flex", flexDirection: "row", gap: 2}}>
                                    <Grid item>
                                        <FilterEXM
                                            placeholders={filterPlaceholders}
                                            periodo={periodo}
                                            setPeriodo={setPeriodo}
                                            specificDate={specificDate}
                                            setSpecificDate={setSpecificDate}
                                            arrowCounter={arrowCounter}
                                            setArrowCounter={setArrowCounter}
                                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                                        />
                                        {
                                            periodo === "custom_periodo" && (
                                                <DateRangePicker
                                                    value={customPeriodo}
                                                    onChange={handleDateChange}
                                                    localeText={{ start: "Início", end: "Fim" }}
                                                    format="DD/MM/YYYY"
                                                    sx={{width: "330px", marginTop: 2, "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}}}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                        }
                                                    }}
                                                />  
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            periodo={periodo}
                                            setSearchValue={setSearchValue}
                                            searchValue={searchValue}
                                            functionProps={getExtratoMovimentacoes}
                                            className="input-text"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterWithTag
                                            placeholderProp="Conta"
                                            options={contas}
                                            setOptions={setContasSelected}
                                            previousValue={previousContas}
                                            widthSetting={"305px"}
                                            optionFilter
                                        />
                                    </Grid>
                                    <Grid item>
                                        <GrayButton
                                            title="Filtrar"
                                            onClick={() => getExtratoMovimentacoes()}
                                        />
                                    </Grid>
                                    <Grid item xs sx={{display: "flex", flex: 1, gap: 2, justifyContent: "end", alignItems: "start", flexWrap: "no-wrap"}}>
                                        <OptionsNovaEXM
                                        
                                        />
                                        <OptionsMenuEXM
                                            className="button_options"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                moreFilters && (
                                    <Grid item xs={7} sx={{marginTop: 2, marginBottom: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                                        <FormControl size="small" sx={{width: "250px"}}>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Categoria</InputLabel>
                                            <Select
                                                value={inputCategoria}
                                                label="Categoria"
                                                sx={{
                                                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                    width: "250px",
                                                    "& .MuiInputBase-input": {
                                                        color: temaEscuro && WHITE_ESCRITA_THEME,
                                                    },
                                                }}
                                                onChange={(e) => setInputCategoria(e.target.value)}
                                            >
                                                {categorias.map((categoria) => {
                                                    return (
                                                        <MenuItem data-testid="menu-item" key={categoria} value={categoria}>
                                                            {categoria}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small" sx={{width: "250px"}}>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Fornecedor</InputLabel>
                                            <Select
                                                value={inputFornecedor}
                                                label="Fornecedor"
                                                sx={{
                                                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                    width: "250px",
                                                    "& .MuiInputBase-input": {
                                                        color: temaEscuro && WHITE_ESCRITA_THEME,
                                                    },
                                                }}
                                                onChange={(e) => setInputFornecedor(e.target.value)}
                                            >
                                                {fornecedores.map((fornecedor) => {
                                                    return (
                                                        <MenuItem data-testid="menu-item" key={fornecedor} value={fornecedor}>
                                                            {fornecedor}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small" sx={{width: "250px"}}>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Tipo da movimentação</InputLabel>
                                            <Select
                                                value={inputTipoMovimentacao}
                                                label="Tipo da movimentação"
                                                sx={{
                                                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                    width: "250px",
                                                    "& .MuiInputBase-input": {
                                                        color: temaEscuro && WHITE_ESCRITA_THEME,
                                                    },
                                                }}
                                                onChange={(e) => setInputTipoMovimentacao(e.target.value)}
                                            >
                                                {tiposMovimentacoes.map((tipo) => {
                                                    return (
                                                        <MenuItem data-testid="menu-item" key={tipo} value={tipo}>
                                                            {tipo}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sx={{fontSize:  "14px", color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME, display: "flex", gap: 0.5, alignItems: "center", cursor: "pointer"}} onClick={openMoreFilters}>
                                        {
                                            moreFilters ? (
                                                <RemoveCircleOutlineIcon sx={{fontSize:  "18px", color: RED_INFO}} />
                                            ) : (
                                                <AddCircleOutlineIcon sx={{fontSize:  "18px", color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME}} />
                                            )
                                        }
                                        <span>
                                            <p>Filtros</p>
                                        </span>
                                    </Grid>
                                    <Grid item sx={{fontSize:  "14px", color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME, display: "flex", gap: 0.5, alignItems: "center", cursor: "pointer"}} onClick={clearAllFilter}>
                                        <DeleteOutlineIcon sx={{fontSize:  "18px", color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME}} />
                                        <span>
                                            <p>Limpar filtros</p>
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 3}}>
                                <Grid container spacing={2} rowSpacing={4}>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_1"
                                            title="Receitas em aberto (R$)"
                                            testId="receita-aberto-kpi"
                                            data={receitaEmAbertoKPI}
                                            handleClick={() => handleClickKPIs("receitaEmAberto")}
                                            selectedKPI={selectedKPI === "receitaEmAberto"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_2"
                                            title="Receitas realizadas (R$)"
                                            testId="receita-realizada-kpi"
                                            data={receitaRealizadaKPI}
                                            handleClick={() => handleClickKPIs("receitaRealizada")}
                                            selectedKPI={selectedKPI === "receitaRealizada"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_3"
                                            title="Despesas em aberto (R$)"
                                            testId="despesa-aberto-kpi"
                                            data={despesaEmAbertoKPI}
                                            handleClick={() => handleClickKPIs("despesaEmAberto")}
                                            selectedKPI={selectedKPI === "despesaEmAberto"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            className="card_4"
                                            title="Despesas realizadas (R$)"
                                            testId="despesa-realizada-kpi"
                                            data={despesaRealizadaKPI}
                                            handleClick={() => handleClickKPIs("despesaRealizada")}
                                            selectedKPI={selectedKPI === "despesaRealizada"}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <EXMKPI
                                            isTotal
                                            className="card_5"
                                            title="Total do período (R$)"
                                            testId="total-periodo-kpi"
                                            data={totalPeriodoKPI}
                                            handleClick={() => handleClickKPIs("totalPeriodo")}
                                            selectedKPI={selectedKPI === "totalPeriodo"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 2, height: "470px"}}>
                                <DataGridWithPagination
                                    pagination
                                    checked
                                    rows={(row) => (`${row.id}-${row.data_pagamento}-${row.categoria}-${row.descricao}-${row.situacao}-${row.valor}`)}
                                    columns={columns}
                                    data={tableData}
                                    customRowSelectionModelChange={(item) => setSelectedItem(item)}
                                    exmItems={selectedItem}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 2}}>
                                <EXMFooter
                                    valorTotal={totalPeriodoKPI}
                                    periodo={footerPeriodo}
                                    testId="footer-kpi"
                                />
                            </Grid>
                        </Grid>
                    )
                )}
            </Paper>
        </div>
    );
};

export default EXM;