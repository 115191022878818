import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../axios";
import Grid from "@mui/material/Grid";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Skeleton from "@mui/material/Skeleton";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import GrayButton from "../../components/Buttons/GrayButton";
import Tour from "reactour";
import { steps } from "./TourPerfil";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import { Avatar, Table, TableBody, TableContainer } from "@mui/material";
import {readFile} from "../../components/Crop/CropUtils";
import Crop from "../../components/Crop/Crop";
import {
    changeImagePerfil,
    changeName, changeNotifications, changeTema,
} from "../../actions/AuthActions";
import {changeStore} from "../../actions/StoreActions";
import {
    GRAY_LABEL_UX,
    PERFIL_THEME,
    GRAY_HEADER_UX,
    GRAY_BG_UX,
    GRAY_WEAK_TABLE,
    GRAY_STRONG_TABLE,
    WHITE_THEME_BLACK,
    GRAY_STRONG_UX,
    PAPER_PADDING_THEME,
    BLUE_THEME,
    paperStyle,
    avatarStyle,
    YELLOW_BG_UX,
    textStylePerfil,
    circlePerfil,
    BORDER_TABLE, 
    WHITE_TABLE, 
    BLACK_LABEL_UX, 
    LINE_TABLE,
    DASH_BAR_COLOR,
    translateAutocomplete,
    GRAY_LABEL_UX_TEMA,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER
} from "../../shared/utils";
import {showSnackMessage} from "../../actions/SnackActions";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import InputPhone from "../../components/Input/InputPhone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import DataGridWithPagination from "../../components/DataGrid/DataGridWithPagination";

const themes = [
    { label: "Tema Claro", value: false },
    { label: "Tema Escuro", value: true },
  ];

export default function Perfil() {

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedTab, setSelectedTab] = useState(0);
    const [name, setName] = useState("");
    const [telefone, setTelefone] = useState("");
    const [initialName, setInitialName] = useState("");
    // const [lojas, setLojas] = useState([]);
    const [sessoes, setSessoes] = useState([]);
    const [loadingPerfil, setLoadingPerfil] = useState(false);
    const [loadingSessao, setLoadingSessao] = useState(false);
    const [loadingNotificacoes, setLoadingNotificacoes] = useState(false);
    const [store, setStore] = useState();
    const [selectedTheme, setSelectedTheme] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const imagePerfil = useSelector(state => state.AuthReducer.imagePerfil);
    const lojas = useSelector(state => state.StoreReducer.lojas);
    const [croppedImage, setCroppedImage] = useState(null);
    const [openCropModal, setOpenCrop] = useState(false);
    const [data, setData] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        // getLojas();
        getSessoes();
        getUserInfo();
        getNotifications();
        if (location.state) {
            setSelectedTab(location.state.selectedTab);
        }
    }, []);

    const handleChange = (event, newTabIdx) => {
        setSelectedTab(newTabIdx);
    };

    const handleChangeStore = (storeObj) => {
        if (storeObj) {
            dispatch(changeStore(storeObj));
            setStore(storeObj);
        } else {
            setStore({ label: "", value: "" });
        }
    };

    const initals = () => {
        let nameSplit = initialName.split(" ").map((word) => word[0]).join("");
        return nameSplit;
    };


    const getUserInfo = async () => {
        setLoadingPerfil(true);
        api.UserInfo()
            .then((response) => {
                setLoadingPerfil(false);
                let name = response.data.nome;
                let telefone = response.data.telefone;
                let lojaPadrao = response.data.loja_padrao;
                let lojaNome = response.data.loja_nome;
                let _temaEscuro = response.data.tema_escuro;
                let s3_path_image = response.data.s3_path_image;
                let notificacoes = response.data.notifications;
                dispatch(changeName(name));
                dispatch(changeTema(_temaEscuro));
                dispatch(changeImagePerfil(s3_path_image));
                dispatch(changeNotifications(notificacoes));
                setName(name);
                setTelefone(telefone);
                setInitialName(name);
                setSelectedTheme(temaEscuro);
                if (lojaPadrao != null) {
                    dispatch(changeStore(lojaPadrao));
                    setStore({ label: lojaNome, value: lojaPadrao });
                }
            })
            .catch(() => {
                setLoadingPerfil(false);
            });
    };

    
    const handleThemeChange = (event, newValue) => {
        if (newValue) {
            setSelectedTheme(newValue.value);
        }
    };

    const updateUser = () => {
        setLoadingPerfil(true);

        const formData = new FormData();

        formData.append("new_name", name);
        formData.append("new_telefone", telefone);
        if (store?.value != null) {
            formData.append("loja_padrao_id", store?.value);
        }
        formData.append("tema_escuro", selectedTheme);
        
        if (croppedImage) {
            formData.append("selected_file", croppedImage);
        }

        api.UpdateUser(formData).then(() => {
            setLoadingPerfil(false);
            dispatch(showSnackMessage({message: "Perfil atualizado com sucesso!"}));
            dispatch(changeName(name));
            dispatch(changeStore(store));
            setInitialName(name);
            window.location.reload();
        }).catch((error) => {
            let msgState = error?.response?.data?.msg;
            setLoadingPerfil(false);
            dispatch(showSnackMessage({message: msgState || "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const deleteImage = () => {
        setLoadingPerfil(true);

        const formData = new FormData();
        formData.append("selected_file", "");
        formData.append("delete_image", true);
        
        api.UpdateUser(formData).then(() => {
            setLoadingPerfil(false);
            dispatch(showSnackMessage({message: "Imagem deletada com sucesso!"}));
            window.location.reload();
        }).catch((error) => {
            let msgState = error?.response?.data?.msg;
            setLoadingPerfil(false);
            dispatch(showSnackMessage({message: msgState || "Algo deu errado tente novamente", severity: "error"}));
        });
    };

    const getSessoes = () => {
        setLoadingSessao(true);
        api.GetSession().then(response => {
            setLoadingSessao(false);
            let data = response.data;
            setSessoes(data);
        }).catch(() => {
            setLoadingSessao(false);
        });
    };

    const getNotifications = () => {
        setLoadingPerfil(true);
        api.GetNotifications().then(response => {
            setLoadingPerfil(false);
            let data = response.data;
            setData(data);
        }).catch(() => {
            setLoadingPerfil(false);
        });
    };

    const deleteSession = (item) => {
        setLoadingSessao(true);
        let data = {
            token: item.token,
        };
        api.DeleteSession(data).then(() => {
            setLoadingSessao(false);
            dispatch(showSnackMessage({message: "Sessão desconectada com sucesso!"}));
            getSessoes();
        }).catch(() => {
            setLoadingSessao(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleReadNotification = () => {
        let data = {
            list_notifications: notifications,
        };
        setLoadingNotificacoes(true);
        api.ReadNotification(data).then(() => {
            setLoadingNotificacoes(false);
            dispatch(showSnackMessage({message: "Notificações lidas com sucesso", severity: "success"}));
            setNotifications([]);
            getNotifications();
            getUserInfo();
        }).catch(() => {
            setLoadingNotificacoes(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const updatePassword = () => {
        navigate("/mudar_senha", {state: {isPerfil: true}});
    };
    
    const changeHandler = async (event) => {
        const file = event.target.files[0];
        let imageDataUrl = await readFile(file);
        setSelectedFile(imageDataUrl);
        setOpenCrop(true);
    };

    const changeOpen = () => {
        setStartAt(0);
        setOpen(!open);
    };

    const columns = [
        { 
            field: "data_criacao", 
            headerName: "Data/Hora", 
            flex: 1,
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            maxWidth: 250,
        },
        { 
            field: "mensagem", 
            headerName: "Mensagem", 
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lida",
            headerName: "Lida",
            flex: 1,
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            maxWidth: 250,
            renderCell: (params) => {
                return (
                    <CheckCircleIcon 
                        sx={{
                            color: params.value === true 
                                ? YELLOW_BG_UX 
                                : temaEscuro 
                                    ? GRAY_LABEL_UX_TEMA 
                                    : BORDER_PAPER,
                            marginTop: 1.7
                        }} 
                    />
                );
            }
        }
    ];

    return (
        <div className="main">
            <Header title="Perfil"/>
            {
                loadingPerfil ? (
                    <React.Fragment>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Grid container spacing={2} style={{display: "flex", flexDirection: "row"}}>
                                        <Grid item>
                                            <Skeleton height={75} width={110} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton height={75} width={110} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid marginTop={5} style={{display: "flex", flexDirection: "row", gap: 50}}>
                                <Skeleton variant="circular" height={110} width={110}/>
                                <Skeleton variant="rounded" width={300} height={250}/>
                                <Skeleton variant="rounded" width={300} height={250}/>

                            </Grid>
                        </div>
                    </React.Fragment>
                ) : (
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                            backgroundImage: temaEscuro && "none", 
                            border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}
                        data-testid="perfil-screen"
                    >
                        <Grid container sx={{display:"flex", justifyContent:"space-between"}}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor: "transparent"}}}>
                            <Tab
                                label="Perfil"
                                data-testid="button-tab-perfil"
                                className="perfil"
                                key={0}
                                sx={{
                                    borderRadius: "20px",
                                    color: temaEscuro ? WHITE_THEME_BLACK :  GRAY_LABEL_UX,
                                    backgroundColor: selectedTab === 0 ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                        color: temaEscuro ? PERFIL_THEME :  GRAY_LABEL_UX,
                                    },
                                }}
                            />
                            <Tab
                                label="Sessões"
                                data-testid="button-tab-sessao"
                                className="sessoes"
                                key={1}
                                sx={{
                                    borderRadius: "20px",
                                    color : temaEscuro ? WHITE_THEME_BLACK :  GRAY_LABEL_UX,
                                    backgroundColor: selectedTab === 1 ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                        color : temaEscuro ? PERFIL_THEME :  GRAY_LABEL_UX,
                                    },
                                }}
                            />
                            <Tab
                                label="Notificações"
                                data-testid="button-tab-notifications"
                                className="notificacoes"
                                key={2}
                                sx={{
                                    borderRadius: "20px",
                                    color : temaEscuro ? WHITE_THEME_BLACK :  GRAY_LABEL_UX,
                                    backgroundColor: selectedTab === 2 ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                        color : temaEscuro ? PERFIL_THEME :  GRAY_LABEL_UX,
                                    },
                                }}
                            />
                        </Tabs>
                            <Grid item>
                            {selectedTab !== 1 ? (
                                <IconButton style={{ background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()} >
                                    <LightbulbIcon style={{ color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}} />
                                </IconButton>
                            ) : null}
                            </Grid>
                                <Tour
                                    steps={steps}
                                    isOpen={open}
                                    onRequestClose={() => changeOpen()}
                                    accentColor={DASH_BAR_COLOR}
                                    startAt={startAt}
                                    rounded={5}
                                    showNavigation={false}
                                    inViewThreshold={60}
                                    {...( temaEscuro ? {className: "tour"} : {})}
                                    badgeContent={(curr, tot) => `${curr}/${tot}`}
                                />
                        </Grid>
                        {
                            selectedTab === 0 && (
                                <Grid container columnGap={8} marginTop={5} marginBottom={6} marginLeft={6} data-testid="perfil">
                                    <Grid item sx={{display: "flex", flexDirection: "column"}} data-testid="profile-image">
                                        <Avatar 
                                            sx={avatarStyle} 
                                            className="camera"
                                            style={{...circlePerfil.fontCircle, ...circlePerfil.paddingCircle, overflow: "hidden", margin: "0 auto"}}
                                        >
                                            {
                                                imagePerfil !== "" ? (
                                                    <img src={imagePerfil} alt="image" style={{maxHeight: "150px", maxWidth: "150px"}}/>   
                                                ) : (
                                                    <React.Fragment>
                                                       {initals()}
                                                    </React.Fragment>
                                                )
                                            }
                                        </Avatar>
                                        <div style={{...styles.containerIconsImage, backgroundColor: temaEscuro ? BLUE_THEME : GRAY_WEAK_TABLE}}>
                                            <DeleteIcon
                                                style={{...styles.styleImageIcon, ...circlePerfil.ImageCircle, color: temaEscuro ? "white" : GRAY_STRONG_TABLE}}
                                                onClick={deleteImage}
                                                data-testid="delete-image-btn"
                                            />
                                            <Crop
                                                imageFile={selectedFile}
                                                openModal={openCropModal}
                                                setOpenModal={setOpenCrop}
                                                setCroppedImage={setCroppedImage}
                                                hiddenFileInput={hiddenFileInput}
                                            />
                                        </div>
                                        <input 
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={changeHandler}
                                            style={{display: "none"}} />
                                        {croppedImage ?
                                            <div style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK}}>
                                                <span>Última modificação do arquivo: {(new Date(croppedImage?.lastModified).toLocaleString("pt-br"))}</span>
                                            </div>
                                        : null}
                                    </Grid>
                                    <Grid item className="info" xs={4} sx={{display: "flex", flexDirection: "column", gap: 3}} >
                                        <b style={{...textStylePerfil, color: temaEscuro ? LINE_TABLE : GRAY_HEADER_UX}}>Informações Gerais</b>
                                        <TextField
                                            style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                            label="Nome"
                                            value={name}
                                            data-testid="name"
                                            variant="outlined"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <InputPhone
                                            telefone={telefone}
                                            setTelefone={setTelefone}
                                        />
                                        <Autocomplete
                                            noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                            onChange={(event, newValue) => {
                                                handleChangeStore(newValue);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            data-testid="select-store"
                                            value={store || null}
                                            options={lojas}
                                            renderInput={(params) => <TextField {...params} label="Loja padrão" />}
                                        />
                                        <Autocomplete
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                                        color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                            style={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                            onChange={handleThemeChange}
                                            data-testid="select-theme"
                                            value={themes.find((theme) => theme.value === selectedTheme)}
                                            options={themes}
                                            renderInput={(params) => <TextField {...params} label="Tema" />}
                                          />
                                        <Grid container marginTop={"70px"} columnSpacing={1}>
                                            <Grid item >
                                                <GrayButton
                                                    title={"Salvar"}
                                                    onClick={() => updateUser()}
                                                    testId="button-save"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <GrayButton
                                                    title={"Redefinir senha"}
                                                    onClick={() => updatePassword()}
                                                    testId="button-edit"
                                                    className="redefinir"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{display: "flex", flexDirection: "column", gap: 3}} className="autorizadas">
                                        <b style={{...textStylePerfil, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_HEADER_UX}}>Lojas Autorizadas</b>
                                        <TableContainer component={Paper} sx={{ maxHeight: 270, backgroundColor: temaEscuro && BLUE_THEME, border: temaEscuro && `1px solid ${BORDER_TABLE}`, backgroundImage: temaEscuro && "none"}} data-testid="lojas">
                                            <Table>
                                                <TableBody>
                                                    {lojas?.map((item) => (
                                                        <TableRow
                                                            key={`${item.id}-${item.label}-${item.value}`}
                                                        >
                                                            <TableCell style={{borderColor: temaEscuro && BORDER_TABLE, color:temaEscuro ? LINE_TABLE : GRAY_HEADER_UX}}align="center">{item.label}</TableCell>
                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            selectedTab === 1 && (
                                <React.Fragment>
                                    {
                                        loadingSessao ? (
                                            <React.Fragment>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    {
                                                        [...Array(14).keys()].map((i,d) =>{
                                                            return(
                                                                <Skeleton height={50} key={d}/>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <Grid container alignItems="center" marginTop={"20px"} data-testid="sessao">
                                                <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: 3}}>
                                                    <TableContainer component={Paper} sx={{borderRadius: 2, border: temaEscuro && `1px solid ${BORDER_TABLE}`}}>
                                                        <Table aria-label="simple table" style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" className="title-ca" style={{color: temaEscuro ? WHITE_THEME_BLACK : BLACK_LABEL_UX}}>Data</TableCell>
                                                                    <TableCell align="center" className="title-ca" style={{color: temaEscuro ? WHITE_THEME_BLACK : BLACK_LABEL_UX}}>IP</TableCell>
                                                                    <TableCell align="center" className="title-ca" style={{color: temaEscuro ? WHITE_THEME_BLACK : BLACK_LABEL_UX}}>Dispositivo</TableCell>
                                                                    <TableCell align="center" className="title-ca" style={{color: temaEscuro ? WHITE_THEME_BLACK : BLACK_LABEL_UX}}>Ação</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {sessoes.map((item, idx) => (
                                                                    <TableRow key={`${item.id}-${item.device}-${idx}`}>
                                                                        <TableCell align="center" style={{color: temaEscuro && WHITE_THEME_BLACK}}>{moment(item.created).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                                                        <TableCell align="center" style={{color: temaEscuro && WHITE_THEME_BLACK}}>{item.ip}</TableCell>
                                                                        <TableCell align="center" style={{color: temaEscuro && WHITE_THEME_BLACK}}>{item.device}</TableCell>
                                                                        <TableCell align="center" style={{color: temaEscuro && WHITE_THEME_BLACK}}>
                                                                            <GrayButton 
                                                                                title={"Desconectar"}
                                                                                onClick={() => deleteSession(item)}
                                                                                testId="disconnect"
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                        {
                            selectedTab === 2 && (
                                <React.Fragment>
                                    {
                                        loadingNotificacoes ? (
                                            <React.Fragment>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    {
                                                        [...Array(14).keys()].map((i,d) =>{
                                                            return(
                                                                <Skeleton height={50} key={d}/>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <Grid container marginTop={"20px"} data-testid="notifications">
                                                <Grid item xs={12} sx={{height: "379px"}}>
                                                    <DataGridWithPagination
                                                        pagination
                                                        checked
                                                        rows={(row) => row.id}
                                                        columns={columns}
                                                        data={data}
                                                        customRowSelectionModelChange={(item) => setNotifications(item)}
                                                        notifications={notifications}
                                                        isRowSelectable={(params) => !params.row.lida}
                                                        handleReadNotification={handleReadNotification}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </Paper>
                )
            }

        </div>
    );
}

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX,
        maxWidth: 160,
        marginTop: 20
    },
    styleImageIcon: {
        fontSize: 20,
        padding: 5,
        borderRadius: 50
    },
    containerIconsImage: {
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        margin: "10px auto",
        gap: 10,
        padding: "5px 10px", 
        borderRadius: 50
    }
};
