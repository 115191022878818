import React, {useEffect, useState}  from "react";
import Button from "@mui/material/Button";
import {
	styleButton,
	muiRootStyle,
	textCompleteStyleBlack,
	WHITE_THEME_BLACK,
	BLUE_THEME,
	GRAY_LABEL_UX,
	BORDER_BUTTON,
	getCurrentDate,
	getFullMonthDate,
	WHITE_ESCRITA_THEME,
	RED_INFO
} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {useSelector} from "react-redux";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";


const FilterDayMonthYear = (props) => {

	const {handleSubmit, optionFilter, width, isConta, isContaPoupanca, isEditConta, inputError} = props;

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	
	const [selectedDate, setSelectedDate] = useState();
	const currentMonthDate = isConta ? moment(getFullMonthDate()).toDate() : moment(getCurrentDate()).toDate();

	useEffect(() => {
		if (props?.value) {
			setSelectedDate(props.value);
		} else if (isConta || isContaPoupanca || isEditConta) {
			setSelectedDate(null);
		} else {
			setSelectedDate(currentMonthDate);
		}
	}, []);

	const getDayMonthYear = (newValue) => {
		let date =  selectedDate;
		if (newValue instanceof Date){
			date = newValue;
		}
		const formattedDia = moment(date).format("DD");
		const formattedMes = moment(date).format("MM");
		const formattedAno = moment(date).format("YYYY");
		if (props?.setState) {
			handleSubmit(formattedAno, formattedMes, formattedDia, props.setState);
			return;
		} else if (props?.index) {
			handleSubmit(formattedAno, formattedMes, formattedDia, props.index);
			return;
		}
		handleSubmit(formattedAno, formattedMes, formattedDia);
	};

	return (
		<Grid container sx={{display: "flex", alignItems: "center", width: width}} >
			<Grid item xs={12}>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
					<DatePicker
						label={props?.label}
						views={["year", "month", "day"]}
						minDate={(isContaPoupanca || isEditConta) ? null : currentMonthDate}
						maxDate={isConta && new Date()}
						value={selectedDate || props?.value}
						sx={border}
						onChange={(newValue) => {
							setSelectedDate(newValue);
							if (!optionFilter){
								getDayMonthYear(newValue);
							}
						}}
                        disabled={props?.disabled}
						slots={{
							openPickerIcon: CalendarTodayOutlinedIcon,
							textField: (params) => (
								<TextField
									{...params}
									inputProps={{"data-testid": props?.testId, ...params.inputProps}}
									helperText={null}
                                    size={props?.size}
									error={inputError}
									sx={{
										...temaEscuro ? textCompleteStyleBlack : muiRootStyle,
										backgroundColor: temaEscuro ? BLUE_THEME : undefined,
										"& .MuiInputLabel-root": {
											color: temaEscuro ? BORDER_BUTTON : undefined,
											fontSize: "15px"
										},
										"& .MuiFormLabel-asterisk": {
											color: (isConta || isContaPoupanca) && RED_INFO
										}
									}}
									style={{
                                        width: props?.width,
                                        ...(!props?.disabled && {backgroundColor:temaEscuro && BLUE_THEME})
                                    }}
									InputLabelProps={{
										style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" },
									}}
									required={!isEditConta}
								/>
							)
						}}
						slotProps={{
							inputAdornment: {position: props?.iconPosition ? props.iconPosition : "start"},
							textField: { size: "small"}
						}}
					/>
				</LocalizationProvider>
				{
					isConta && (
						<span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 300, fontSize: "12px"}}>Informe uma data até {moment(getCurrentDate()).format("DD/MM/YYYY")} com formato dd/mm/aaaa. </span>
					)
				}
			</Grid>
			{
				optionFilter &&
				(
					<Grid item xs={7}>
						<Button
							variant="contained"
							data-testid="button-filter-year"
							onClick={getDayMonthYear}
							startIcon={<FilterListOutlinedIcon/>}
							style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor:temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}
						>
							Filtrar
						</Button>
					</Grid>
				)
			}
		</Grid>
	);

};

export default React.memo(FilterDayMonthYear);

const border ={
	"& .css-126wocv-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root fieldset": {
		border: "none"
	},
};