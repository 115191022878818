import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getCroppedImg } from "./CropUtils";
import Modal from "@mui/material/Modal";
import {
    GRAY_STRONG_TABLE,
    YELLOW_BG_UX,
    circlePerfil,
    BLACK_BUTTON
} from "../../shared/utils";
import {showSnackMessage} from "../../actions/SnackActions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";


const Crop = (props) => {
    const {imageFile, openModal, setOpenModal, setCroppedImage, hiddenFileInput} = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const dispatch = useDispatch();

    useEffect(() => {
        if (openModal) {
            const croppedAreaPixels = { width: 100, height: 100, x: 0, y: 0 };
            setCroppedAreaPixels(croppedAreaPixels);
        }
    }, [openModal, imageFile]);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageFile,
                croppedAreaPixels,
                rotation
            );
            let file = await
                fetch(croppedImage)
                    .then(response => response.blob())
                    .then(blobData => {
                        const fileName = `yourFileName.${blobData.type.split("/")[1]}`;
                        const file = new File([blobData], fileName, { lastModified: blobData.lastModified, type: blobData.type });

                        return file;
                    });
            setCroppedImage(file);
            setOpenModal(false);
        } catch (e) {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <div >
            <CameraAltIcon
                style={{
                    ...classes.styleImageIcon, ...circlePerfil.ImageCircle,
                    color: temaEscuro ? "white" : GRAY_STRONG_TABLE,
                    position: "relative",
                    top: "3px"
                }}
                onClick={handleClick}
            />

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="download-modal"
            >
                <React.Fragment>
                    <div style={classes.cropContainer}>
                        <Cropper
                            image={imageFile}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropShape="round"
                        />
                    </div>
                    <div style={classes.controls}>
                        <div style={classes.sliderContainer}>
                            <Typography
                                variant="overline"
                                style={classes.sliderLabel}
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                style={{...classes.slider,
                                    color: YELLOW_BG_UX,
                                }}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </div>
                        <Button
                            onClick={showCroppedImage}
                            variant="contained"
                            color="primary"
                            style={{...classes.cropButton,
                                color: BLACK_BUTTON,
                                backgroundColor: YELLOW_BG_UX,
                            }}
                            data-testid="confirm-upload-img"
                        >
                            Confirmar Ajuste
                        </Button>
                    </div>
                </React.Fragment>
            </Modal>
        </div>
    );
};

const classes = {
    styleImageIcon: {
        fontSize: 20,
        padding: 5,
        borderRadius: 50
    },
    cropContainer: {
        marginTop: "1vh",
        position: "relative",
        width: "100%",
        height: "60vh",
        background: "rgba(51, 51, 51, 0)",
    },
    sliderContainer: {
        display: "flex",
        flex: "1",
        alignItems: "center",
    },
    controls: {
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    sliderLabel: {
        minWidth: 65,
        marginLeft: "39vw",
    },
    slider: {
        // padding: "22px 0px",
        marginLeft: 16,
        width: 300,
        position: "flex",
        alignSelf: "center",
        justifyContent: "center",
        aligntItems: "center",
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
        marginTop: 16,
        width: 300,
        position: "flex",
        alignSelf: "center",
    },
};



export default Crop;
