import React from "react";
import {
    smallPaperStyle, 
    GRAY_LABEL_UX, 
    styleTitleModal, 
    BLUE_THEME, 
    WHITE_ESCRITA_THEME, 
    BORDER_TABLE, 
    styleText,
} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import FormContaCorrente from "../Forms/FormContaCorrente";
import FormCaixinha from "../Forms/FormCaixinha";
import FormCartaoCredito from "../Forms/FormCartaoCredito";
import FormPoupanca from "../Forms/FormPoupanca";
import FormOutraConta from "../Forms/FormOutraConta";


const EditeContaModal = (props) => {

    const {
        openEditModal,
        setOpenEditModal,
        editConta,
        contaDetails,
        inputApelido,
        setInputApelido,
        tipoConta,
        setInputTipoConta,
        inputTipoConta,
        bancos,
        setInputBanco,
        inputBanco,
        inputModalidade,
        setInputModalidade,
        modalidades,
        isContaPadrao,
        setIsContaPadrao,
        isBancoEdit,
        handleDayMonthYear,
        setInputSaldoInicial,
        inputSaldoInicial,
        cartaoEmissores,
        inputCartaoEmissor,
        setInputCartaoEmissor,
        inputCartaoContaPagamento,
        setInputCartaoContaPagamento,
        contasPagamento,
        inputCartaoBandeira,
        setInputCartaoBandeira,
        bandeiras,
        inputUltimosDigitos,
        setInputUltimosDigitos,
        inputDiaVencimento,
        setInputDiaVencimento,
        inputDiaFechamento,
        setInputDiaFechamento,
        selectedDate,
        setInputSaldoFinal,
        inputSaldoFinal,
        setInputContaVinculada,
        inputContaVinculada,
        contasCorrente
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return(
        <Modal
            open={openEditModal}
            onClose={() => setOpenEditModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="info-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "35%"}}>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: "700"}}>Alterar Conta</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Informações da Conta</span>
                        <Grid container sx={{marginTop: 4}}>
                            <Grid item xs={12} sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
                                {
                                    contaDetails.tipo === "Conta Corrente" && (
                                        <FormContaCorrente
                                            contaDetails={contaDetails}
                                            bancos={bancos}
                                            setInputBanco={setInputBanco}
                                            inputBanco={inputBanco}
                                            inputModalidade={inputModalidade}
                                            setInputModalidade={setInputModalidade}
                                            modalidades={modalidades}
                                            isContaPadrao={isContaPadrao}
                                            setIsContaPadrao={setIsContaPadrao}
                                            setInputTipoConta={setInputTipoConta}
                                            inputTipoConta={inputTipoConta}
                                            tipoConta={tipoConta}
                                            inputApelido={inputApelido}
                                            setInputApelido={setInputApelido}
                                            isBancoEdit={isBancoEdit}
                                        />
                                    )
                                }
                                {
                                    contaDetails.tipo === "Caixinha" && (
                                        <FormCaixinha
                                            inputTipoConta={inputTipoConta}
                                            setInputTipoConta={setInputTipoConta}
                                            contaDetails={contaDetails}
                                            tipoConta={tipoConta}
                                            inputApelido={inputApelido}
                                            setInputApelido={setInputApelido}
                                            isBancoEdit={isBancoEdit}
                                            handleDayMonthYear={handleDayMonthYear}
                                            inputSaldoFinal={inputSaldoFinal}
                                            setInputSaldoFinal={setInputSaldoFinal}
                                            selectedDate={selectedDate}
                                        />
                                    )
                                }
                                {
                                    contaDetails.tipo === "Cartão de Crédito" && (
                                        <FormCartaoCredito
                                            inputTipoConta={inputTipoConta}
                                            setInputTipoConta={setInputTipoConta}
                                            contaDetails={contaDetails}
                                            tipoConta={tipoConta}
                                            inputApelido={inputApelido}
                                            setInputApelido={setInputApelido}
                                            isBancoEdit={isBancoEdit}
                                            cartaoEmissores={cartaoEmissores}
                                            inputCartaoEmissor={inputCartaoEmissor}
                                            setInputCartaoEmissor={setInputCartaoEmissor}
                                            inputCartaoContaPagamento={inputCartaoContaPagamento}
                                            setInputCartaoContaPagamento={setInputCartaoContaPagamento}
                                            contasPagamento={contasPagamento}
                                            inputCartaoBandeira={inputCartaoBandeira}
                                            setInputCartaoBandeira={setInputCartaoBandeira}
                                            bandeiras={bandeiras}
                                            inputUltimosDigitos={inputUltimosDigitos}
                                            setInputUltimosDigitos={setInputUltimosDigitos}
                                            inputDiaVencimento={inputDiaVencimento}
                                            setInputDiaVencimento={setInputDiaVencimento}
                                            inputDiaFechamento={inputDiaFechamento}
                                            setInputDiaFechamento={setInputDiaFechamento}
                                        />
                                    )
                                }
                                {
                                    ["Investimento", "Poupança", "Aplicação Automática"].includes(contaDetails.tipo) && (
                                        <FormPoupanca
                                            inputTipoConta={inputTipoConta}
                                            setInputTipoConta={setInputTipoConta}
                                            contaDetails={contaDetails}
                                            tipoConta={tipoConta}
                                            inputApelido={inputApelido}
                                            setInputApelido={setInputApelido}
                                            isBancoEdit={isBancoEdit}
                                            handleDayMonthYear={handleDayMonthYear}
                                            inputSaldoInicial={inputSaldoInicial}
                                            setInputSaldoInicial={setInputSaldoInicial}
                                            selectedDate={selectedDate}
                                            inputModalidade={inputModalidade}
                                            setInputModalidade={setInputModalidade}
                                            modalidades={modalidades}
                                            bancos={bancos}
                                            setInputBanco={setInputBanco}
                                            inputBanco={inputBanco}
                                            setInputContaVinculada={setInputContaVinculada}
                                            inputContaVinculada={inputContaVinculada}
                                            contasCorrente={contasCorrente}
                                        />
                                    )
                                }
                                {
                                    contaDetails.tipo === "Outro tipo de conta" && (
                                        <FormOutraConta
                                            contaDetails={contaDetails}
                                            tipoConta={tipoConta}
                                            inputApelido={inputApelido}
                                            setInputApelido={setInputApelido}
                                            isBancoEdit={isBancoEdit}
                                            handleDayMonthYear={handleDayMonthYear}
                                            inputSaldoFinal={inputSaldoFinal}
                                            setInputSaldoFinal={setInputSaldoFinal}
                                            selectedDate={selectedDate}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 5, display: "flex", gap: 2, alignItems: "center", justifyContent: "end"}}>
                        <GrayButton 
                            title="Cancelar"
                            onClick={() => setOpenEditModal(false)}
                        />
                        <YellowButton
                            title="Confirmar"
                            onClick={() => editConta(contaDetails.tipo)}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );                  
};

export default React.memo(EditeContaModal);