import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Link as LinkRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import api from "../../axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {loginUser} from "../../actions/AuthActions";
import Loading from "../../components/Loading/Loading";
import InputPassword from "../../components/Input/InputPassword";
import CopyRight from "../../components/CopyRight/CopyRight";
import { GRAY_LABEL_UX, RED_ERROR_UX, GRAY_HEADER_UX, styleYellowButton, GRAY_BORDER_TABLE, LINE_TABLE, GRAY_BG_BODY } from "../../shared/utils";
import "./Login.css";
import ReCAPTCHA from "react-google-recaptcha";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Inter, cursive",
            color: GRAY_LABEL_UX,
            fontSize: "17px",
            fontWeight: "500",
        },
        button: {
            textTransform: "none",
        }
    },
});


export default function Login() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    // eslint-disable-next-line no-undef
    const [recaptcha, setRecaptcha] = useState(process.env.NODE_ENV === "test" ? "test" : "");

    const deviceToken = useSelector(state => state.AuthReducer.deviceToken);
    
    const handleRecaptchaChange = (value) => {
        setRecaptcha(value);
    };

    const handleSubmit = () => {
        const data = {
            username: email,
            password,
            recaptcha_token: recaptcha,
            device_token: deviceToken
        };
        setLoading(true);
        api.GetLogin(data).then(response => {
            let token = response.data.token;
            setLoading(false);
            dispatch(loginUser(token));
            window.location.reload();
        }).catch(error => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                setPasswordError(true);
            }
        });
    };

    const validateEmail = (email) => {
        if (email === "") {
            setEmailError(false);
            return;
        }
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    return (
        <Box 
            sx={{
                backgroundColor: GRAY_BG_BODY,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Grid container sx={{backgroundColor: LINE_TABLE, width: "500px", borderRadius: 2, border: `1px solid ${GRAY_BORDER_TABLE}`, padding: 2}}>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={styles.header}>Entre em sua conta</p>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h1 style={styles.text}>Insira as credenciais fornecidas para realizar o acesso ao sistema</h1>
                </Grid>
                <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                        <Box component="form" sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <TextField
                                data-testid="email-input"
                                margin="normal"
                                fullWidth
                                label="Email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => {setEmail(e.target.value.trim()); validateEmail(e.target.value.trim());}}
                                value={email}
                                variant="outlined"
                                error={emailError || passwordError}
                                sx={{ 
                                    width: "80%",
                                }}
                            />
                            {
                                emailError && (
                                    <p data-testid="email-error" style={{color: RED_ERROR_UX, fontSize: "12px", marginTop: "-2px"}}>Insira um e-mail válido</p>
                                )
                            }
                            <InputPassword label="Senha" password={password} error={passwordError} handleChange={(e) => setPassword(e.target.value)} />
                            {
                                passwordError && (
                                    <p data-testid="email-pwd-error" style={{color: RED_ERROR_UX, fontSize: "12px"}}>O email ou senha informados estão incorretos!</p>
                                )
                            }
                            <Grid container sx={{marginTop: 2, marginBottom: 2}}>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <LinkRouter data-testid="forgot-password-button" to='/forgot_pwd' variant="body2" style={{textDecoration: "none", color: GRAY_LABEL_UX, fontWeight: "600", fontSize: "14px"}}>
                                        Esqueci minha senha
                                    </LinkRouter>
                                </Grid>
                            </Grid>
                            {/* eslint-disable-next-line no-undef */}
                            <ReCAPTCHA data-testid="recaptcha" sitekey={process.env.REACT_APP_SECRET_KEY} onChange={handleRecaptchaChange} />
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <ThemeProvider theme={theme}>
                                        { (emailError || (recaptcha === "") || (password === "")) ? (
                                            <Button
                                                disabled
                                                data-testid="unabled-submit-button"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ ...styles.buttonSubmit }}
                                                style={{fontWeight: "600"}}
                                            >
                                                Entrar
                                            </Button>
                                        ) : (
                                            <Button
                                                data-testid="submit-button"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ ...styles.buttonSubmit }}
                                                onClick={() => handleSubmit()}
                                                style={styleYellowButton}
                                            >
                                                Entrar
                                            </Button>
                                        )
                                        }
                                    </ThemeProvider>
                                )
                            }
                        </Box>
                    </ThemeProvider>
                </Grid>
                <div style={styles.copyright}>
                    <CopyRight color="black"/>
                </div>
            </Grid>
        </Box>
    );
}

const styles = {
    center: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        userDrag: "none",
        userSelect: "none",
        pointerEvents: "none",
        width: "191px",
        height: "36px"
    },
    header: {
        width: "398px",
        height: "15px",
        fontFamily: "Kallisto",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "32px",
        color: GRAY_HEADER_UX,
    },
    text: {
        width: "411px",
        height: "50px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: GRAY_LABEL_UX,
        textAlign: "center",
    },
    copyright: {
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "end",
        paddingBottom: "40px"
    },
    buttonSubmit: {
        marginTop: 3,
        marginBottom: 2,
        width: "80%",
    }
};

