import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    WHITE_ESCRITA_THEME,
    BORDER_TABLE,
    COLOR_CONTAS1,
    COLOR_CONTAS2,
    COLOR_CONTAS3,
    COLOR_CONTAS4,
    WHITE_THEME_BLACK,
    DRE_PRE_SALVA_GRAY
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import InputSearch from "../../components/Input/InputSearch";
import GrayButton from "../../components/Buttons/GrayButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@mui/material";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import DataGrid from "../../components/DataGrid/DataGrid";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import api from "../../axios";
import Skeleton from "@mui/material/Skeleton";
import {showSnackMessage} from "../../actions/SnackActions";
import DeleteContaModal from "../../components/Modal/DeleteContaModal";
import EditContaModal from "../../components/Modal/EditContaModal";
import moment from "moment";
import {Link} from "react-router-dom";

const Contas = () => {

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contas, setContas] = useState([]);
    const [tipoConta, setTipoConta] = useState([]);
    const [bancos, setBancos] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [contasCorrente, setContasCorrente] = useState([]);
    const [cartaoEmissores, setCartaoEmissores] = useState([]);
    const [contasPagamento, setContasPagamento] = useState([]);
    const [bandeiras, setBandeiras] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [contaDetails, setContaDetails] = useState([]);
    const [inputApelido, setInputApelido] = useState("");
    const [inputTipoConta, setInputTipoConta] = useState("");
    const [inputBanco, setInputBanco] = useState("");
    const [inputModalidade, setInputModalidade] = useState("");
    const [isContaPadrao, setIsContaPadrao] = useState(false);
    const [isBancoEdit, setIsBancoEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [inputSaldoInicial, setInputSaldoInicial] = useState(0.0);
    const [inputCartaoEmissor, setInputCartaoEmissor] = useState("");
    const [inputCartaoContaPagamento, setInputCartaoContaPagamento] = useState("");
    const [inputCartaoBandeira, setInputCartaoBandeira] = useState("");
    const [inputUltimosDigitos, setInputUltimosDigitos] = useState("");
    const [inputDiaVencimento, setInputDiaVencimento] = useState("");
    const [inputDiaFechamento, setInputDiaFechamento] = useState("");
    const [inputSaldoFinal, setInputSaldoFinal] = useState(0.0);
    const [inputContaVinculada, setInputContaVinculada] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getContas();
        }
    }, [estabelecimentoId]);

    const getColorSituacaoIntegracao = (value) => {
        const isAutomaticOrDirect = value === "Automática" || value === "Direta";
    
        if (temaEscuro) {
            return isAutomaticOrDirect ? COLOR_CONTAS2 : WHITE_ESCRITA_THEME;
        } else {
            return isAutomaticOrDirect ? COLOR_CONTAS2 : LINE_TABLE;
        }
    };
    
    const getBackgroundColorSituacaoIntegracao = (value) => {
        const isAutomaticOrDirect = value === "Automática" || value === "Direta";
    
        if (temaEscuro) {
            return isAutomaticOrDirect ? COLOR_CONTAS1 : BORDER_TABLE;
        } else {
            return isAutomaticOrDirect ? COLOR_CONTAS1 : DRE_PRE_SALVA_GRAY;
        }
    };

    const handleOpenModalDeleteConta = (conta) => {
        setContaDetails(conta);
        setOpenDeleteModal(true);
    };

    const handleOpenModalEditConta = (conta) => {
        setContaDetails(conta);
        setInputApelido(conta.apelido);
        setInputTipoConta(conta.tipo);
        setInputBanco(conta.banco_formatted);
        setInputModalidade(conta.modalidade);
        setIsContaPadrao(conta.conta_padrao);
        setIsBancoEdit(conta.is_banco_edit);
        setInputSaldoInicial(conta.saldo_inicial);
        setInputCartaoEmissor(conta.cartao_emissor);
        setInputCartaoContaPagamento(conta.cartao_conta_pagamento);
        setInputCartaoBandeira(conta.cartao_bandeira);
        setInputUltimosDigitos(conta.cartao_ultimos_digitos);
        setInputDiaVencimento(conta.cartao_dia_vencimento);
        setInputDiaFechamento(conta.cartao_dia_fechamento);
        setOpenEditModal(true);
        setInputSaldoFinal(conta.saldo_final);
        setInputContaVinculada(conta.conta_vinculada);
        setSelectedDate(conta.data_competencia);
    };

    const handleDayMonthYear = (ano, mes, dia) => {
        setSelectedDate(`${ano}-${mes}-${dia}`);
    };

    const columns = [
        { 
            field: "apelido", 
            headerName: "Apelido da conta", 
            width: 200, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "banco_formatted", 
            headerName: "Banco", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "tipo", 
            headerName: "Tipo de conta", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "status_cobranca", 
            headerName: "Status cobrança", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                if (params.value == null) {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        { 
            field: "conciliacoes", 
            headerName: "Conciliações", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                if (params.value !== null && params.value !== "") {
                    if (params.value === "Pendentes") {
                        return (
                            <span 
                                style={{
                                    backgroundColor: COLOR_CONTAS3,
                                    padding: 8,
                                    paddingInline: 19,
                                    borderRadius: "80px",
                                    color: COLOR_CONTAS4,
                                    fontWeight: 600 
                                }}
                            >
                                {params.value}
                            </span>
                        );
                    }
                } else {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        { 
            field: "situacao_integracao", 
            headerName: "Situação da integração", 
            width: 200, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                if (params.value != null) {
                    const color = getColorSituacaoIntegracao(params.value);
                    const backgroundColor = getBackgroundColorSituacaoIntegracao(params.value);
                    return (
                        <span 
                            style={{
                                backgroundColor: backgroundColor,
                                padding: 8,
                                paddingInline: 19,
                                borderRadius: "80px",
                                color: color,
                                fontWeight: 600 
                            }}
                        >
                            {params.value}
                        </span>
                    );
                } else {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        {
            field: "acoes",
            headerName: "Ações",
            width: 100,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{height: "100%"}}
                >
                    <CreateIcon
                        sx={{
                            color: temaEscuro ? WHITE_THEME_BLACK : BORDER_TABLE,
                            cursor: "pointer",
                            transition: "opacity 0.3s ease",
                            "&:hover": {
                                opacity: 0.6,
                            },
                        }}
                        onClick={() => handleOpenModalEditConta(params.row)}
                    />
                    <DeleteIcon
                        sx={{
                            color: temaEscuro ? WHITE_THEME_BLACK : BORDER_TABLE,
                            cursor: "pointer",
                            transition: "opacity 0.3s ease",
                            "&:hover": {
                                opacity: 0.6,
                            },
                        }}
                        onClick={() => handleOpenModalDeleteConta(params.row)}
                    />
                </Box>
            ),
        },
    ];

    const getContas = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            search: searchValue
        };
        api.GetContas(dataRequest).then(response => {
            let dataResponse = response.data;
            setLoading(false);
            setContas(dataResponse.contas);
            setTipoConta(dataResponse.tipo_contas);
            setBancos(dataResponse.bancos);
            setModalidades(dataResponse.modalidades);
            setIsBancoEdit(dataResponse.is_banco_edit);
            setCartaoEmissores(dataResponse.cartao_emissores);
            setContasPagamento(dataResponse.cartao_contas_pagamento);
            setBandeiras(dataResponse.bandeiras);
            setContasCorrente(dataResponse.contas_corrente);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const deleteConta = () => {
        setOpenDeleteModal(false);
        setLoading(true);
        const dataRequest = {
            conta_id: contaDetails.id,
            estabelecimento_id: estabelecimentoId
        };
        api.DeleteConta(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Conta deletada com sucesso!", severity: "success" }));
            getContas();
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const editConta = (tipo) => {
        setOpenEditModal(false);
        setLoading(true);
    
        const formatDate = (date) => date instanceof Date ? moment(date).format("DD-MM-YYYY") : date;
    
        const dataRequest = {
            conta_id: contaDetails.id,
            estabelecimento_id: estabelecimentoId,
            apelido: inputApelido,
            conta_tipo: inputTipoConta,
        };
    
        switch (tipo) {
            case "Conta Corrente":
                Object.assign(dataRequest, {
                    banco_nome: inputBanco,
                    modalidade: inputModalidade,
                    conta_padrao: isContaPadrao,
                });
                break;
            case "Caixinha":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_final: inputSaldoFinal || 0.0,
                });
                break;
            case "Cartão de Crédito":
                Object.assign(dataRequest, {
                    cartao_emissor: inputCartaoEmissor,
                    conta_pagamento: inputCartaoContaPagamento,
                    bandeira: inputCartaoBandeira,
                    ultimos_digitos: inputUltimosDigitos,
                    dia_vencimento: inputDiaVencimento,
                    dia_fechamento: inputDiaFechamento,
                });
                break;
            case "Poupança":
            case "Investimento":
            case "Aplicação Automática":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_inicial: inputSaldoInicial || 0.0,
                    conta_vinculada: inputContaVinculada,
                    modalidade: inputModalidade,
                    banco_nome: inputBanco,
                });
                break;
            case "Outro tipo de conta":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_final: inputSaldoFinal || 0.0,
                });
                break;
        }
    
        api.EditConta(dataRequest)
            .then(() => {
                setLoading(false);
                dispatch(showSnackMessage({ message: "Conta alterada com sucesso!", severity: "success" }));
                getContas();
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    dispatch(showSnackMessage({ message: error.response.data, severity: "error" }));
                } else {
                    dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
                }
                setLoading(false);
            });
    };


    return (
        <React.Fragment>
            <DeleteContaModal 
                openDeleteModal={openDeleteModal} 
                setOpenDeleteModal={setOpenDeleteModal}
                deleteConta={deleteConta}
            />
            <EditContaModal 
                openEditModal={openEditModal} 
                setOpenEditModal={setOpenEditModal}
                editConta={editConta}
                contaDetails={contaDetails}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
                tipoConta={tipoConta}
                setInputTipoConta={setInputTipoConta}
                inputTipoConta={inputTipoConta}
                bancos={bancos}
                setInputBanco={setInputBanco}
                inputBanco={inputBanco}
                setInputModalidade={setInputModalidade}
                inputModalidade={inputModalidade}
                modalidades={modalidades}
                isContaPadrao={isContaPadrao}
                setIsContaPadrao={setIsContaPadrao}
                isBancoEdit={isBancoEdit}
                handleDayMonthYear={handleDayMonthYear}
                setInputSaldoInicial={setInputSaldoInicial}
                inputSaldoInicial={inputSaldoInicial}
                inputCartaoEmissor={inputCartaoEmissor}
                setInputCartaoEmissor={setInputCartaoEmissor}
                cartaoEmissores={cartaoEmissores}
                inputCartaoContaPagamento={inputCartaoContaPagamento}
                setInputCartaoContaPagamento={setInputCartaoContaPagamento}
                contasPagamento={contasPagamento}
                inputCartaoBandeira={inputCartaoBandeira}
                setInputCartaoBandeira={setInputCartaoBandeira}
                bandeiras={bandeiras}
                setInputUltimosDigitos={setInputUltimosDigitos}
                inputUltimosDigitos={inputUltimosDigitos}
                setInputDiaFechamento={setInputDiaFechamento}
                setInputDiaVencimento={setInputDiaVencimento}
                inputDiaFechamento={inputDiaFechamento}
                inputDiaVencimento={inputDiaVencimento}
                selectedDate={selectedDate}
                setInputSaldoFinal={setInputSaldoFinal}
                inputSaldoFinal={inputSaldoFinal}
                setInputContaVinculada={setInputContaVinculada}
                inputContaVinculada={inputContaVinculada}
                contasCorrente={contasCorrente}
            />
            <div className="main" data-testid="contas-container">
                <Header title="Contas" dataTestId="header-contas" />
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                        backgroundImage: temaEscuro && "none", 
                        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(15).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        estabelecimentoId === 0 ? (
                            <SelectEstabelecimento/>
                        ) : (
                            <Grid container spacing={2.7} fullWidth sx={{display: "flex", justifyContent: "space-between"}}>
                                <Grid item>
                                    <InputSearch
                                        functionProps={getContas}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        className="input-text"
                                    />
                                </Grid>
                                <Grid item sx={{display: "flex", gap: 2}}>
                                    <Link to="/nova_conta">
                                        <GrayButton
                                            startIcon={<AddCircleOutlineIcon />}
                                            title="Nova Conta"
                                            testId="nova-conta"
                                        /> 
                                    </Link>
                                    <GrayButton
                                        startIcon={<SettingsIcon />}
                                        title="Configurar Conciliação Automática"
                                        testId="configurar-conciliacao-conta"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{maxHeight: 900}}>
                                    <DataGrid
                                        columns={columns}
                                        data={contas}
                                        rows={(row) => row.id}
                                        NoneFooter
                                    />
                                </Grid>
                            </Grid>
                        )
                    )}
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default Contas;