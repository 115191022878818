import React from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BLUE_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    LINE_TABLE,
    RED_INFO
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";


const StepOneContaCorrente = (props) => {

    const {inputBanco, setInputBanco, inputApelido, setInputApelido, setIsContaPadrao, checked, handleChangeChecked, bancos} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>Cadastre a conta corrente do seu banco</h3>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 1}}>
                <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Informe seu banco e crie um apelido para esta conta</span>
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                <FormControl size="small" sx={{width: "250px"}} required>
                    <InputLabel 
                        id="demo-simple-select-label" 
                        sx={{
                            color: temaEscuro && BORDER_BUTTON,
                            fontSize: "15px",
                            "& .MuiFormLabel-asterisk": {
                                color: RED_INFO,
                            }
                        }}
                    >
                        Banco
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inputBanco}
                        label="Banco"
                        sx={{
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            width: "250px",
                            "& .MuiInputBase-input": {
                                color: temaEscuro && WHITE_ESCRITA_THEME,
                            },
                        }}
                        onChange={(e) => setInputBanco(e.target.value)}
                    >
                            {bancos.map((banco) => {
                            return (
                                <MenuItem data-testid="menu-item" key={banco} value={banco}>
                                    {banco}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <TextField
                    label="Apelido"
                    id="outlined-size-small"
                    size="small"
                    value={inputApelido}
                    onChange={(event) => {
                        setInputApelido(event.target.value);
                    }}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: RED_INFO,
                        }
                    }}
                    InputLabelProps={{
                        style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                    }}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel 
                    control={
                        <Checkbox
                            onChange={(e) => setIsContaPadrao(e.target.checked)}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    } 
                    label="Quero que esta seja minha conta padrão"
                    sx={{
                        "& .MuiTypography-root": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                            fontSize: "15px",
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600, marginBottom: 2}}>Tipo de conta</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={checked}
                        onChange={handleChangeChecked}
                    >
                        <FormControlLabel value="PJ" control={<Radio />} label="Conta Pessoa Jurídica (PJ)"  sx={{"& .MuiTypography-root": {fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME}}} />
                        <FormControlLabel value="PF" control={<Radio />} label="Conta Pessoa Física (PF)" sx={{"& .MuiTypography-root": {fontSize: "14px", color: temaEscuro && WHITE_ESCRITA_THEME}}} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default React.memo(StepOneContaCorrente);