export const steps = [
    {
        selector: ".buttonTabs",
        content: "Status das despesas desta loja."
    },
    {
        selector: ".buttonCopyLink",
        content: "Copia o link das despesas desta loja."
    },
    {
        selector: ".buttonArquivo",
        content: "Adiciona novos boletos às despesas."
    }
];