import React from "react";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { INFO_THEME, WHITE_ESCRITA_THEME } from "../../shared/utils";
import { useSelector } from "react-redux";

const ModalTitle = ({ title, setOpenModal }) => {
  const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

  return (
    <React.Fragment>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", color: temaEscuro && WHITE_ESCRITA_THEME}}>
            <h1 style={{fontSize: "18px", fontWeight: "500", lineHeight: "28px"}}>{title}</h1>
            {setOpenModal && (
              <CloseIcon
                onClick={() => setOpenModal(false)}
                sx={{cursor: "pointer"}}
              />
            )}
        </div>
        <Divider sx={{backgroundColor: INFO_THEME}}/>
    </React.Fragment>
  );
};

export default ModalTitle;