import {
	CHANGE_STORE,
	CHANGE_ESTABELECIMENTO,
	CHANGE_LOJAS,
	CHANGE_MODIFIED,
} from "../actions/types";

const INITIAL_STATE = {
	storeId: 0,
	estabelecimentoId: 0,
	lojas: null,
	dataModified: "",
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
	case CHANGE_STORE:
		return { ...state, storeId: action.payload };
	case CHANGE_ESTABELECIMENTO:
		return { ...state, estabelecimentoId: action.payload };
	case CHANGE_LOJAS:
		return { ...state, lojas: action.payload };
	case CHANGE_MODIFIED:
		return { ...state, dataModified: action.payload };
	default:
		return state;
	}
};
