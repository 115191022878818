export const steps = [
    {
        selector: ".perfil", 
        content: "Mostra as suas informações cadastradas no sistema, como lojas autorizadas e foto de perfil. Permite também a alteração de senha e de algumas informações."
    },
    {
        selector: ".sessoes", 
        content: "Mostra a lista de logins ativos no sistema, com a possibilidade de desconectar qualquer sessão desejada."
    },
    {
        selector: ".camera",
        content: "Ao clicar no ícone de câmera, é possível escolher uma foto para o seu perfil. Não esqueça de clicar no botão 'Salvar' para salvar a nova foto escolhida."
    },
    {
        selector: ".info",
        content: "Esse formulário possibilta a alteração de algumas informações e configuração do tema, entre claro e escuro. Não esqueça de clicar no botão 'Salvar' para persistir as alterações feitas."
    },
    {
        selector: ".autorizadas", 
        content: "Mostra a lista de lojas que você tem permissão para visualizar os dados."
    },
    {
        selector: ".redefinir", 
        content: "Clique aqui caso deseje alterar sua senha."
    }
];