import React from "react";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Grid from "@mui/material/Grid";
import {BLUE_THEME, GRAY_BG_UX, YELLOW_BG_UX, BORDER_BUTTON} from "../../shared/utils";

import GrayButton from "../../components/Buttons/GrayButton";

const FilterEXM = (props) => {
    const { placeholders, periodo, setPeriodo, specificDate, setSpecificDate, arrowCounter, setArrowCounter, callFunction } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const defaultBorderRadius = 4;

    const EXMDefaultFilters = [
        {
            "label": placeholders.hoje,
            "value": "hoje",
            "option": "Hoje"
        },
        {
            "label": placeholders.esta_semana,
            "value": "esta_semana",
            "option": "Esta semana"
        },
        {
            "label": placeholders.este_mes,
            "value": "este_mes",
            "option": "Este mês"
        },
        {
            "label": placeholders.este_ano,
            "value": "este_ano",
            "option": "Este ano"
        },
        {
            "label": placeholders.ultimos_trinta_dias,
            "value": "ultimos_trinta_dias",
            "option": "Últimos 30 dias"
        },
        {
            "label": placeholders.ultimos_doze_meses,
            "value": "ultimos_doze_meses",
            "option": "Últimos 12 meses"
        },
        {
            "label": "Todo o período",
            "value": "todo_periodo",
            "option": "Todo o período"
        },
        {
            "label": "Período personalizado",
            "value": "custom_periodo",
            "option": "Período personalizado"
        },
    ];

    const handleChange = (event) => {
        setPeriodo(event.target.value);
        setArrowCounter(0);
        setSpecificDate(null);
    };

    const handleArrowClick = (direction) => {
        setArrowCounter(arrowCounter + direction);
    };

    return (
        <Grid container sx={{display: "flex", flexWrap: "nowrap"}}>
            {periodo !== "custom_periodo" && periodo !== "todo_periodo" && (
                <Grid item>
                    <ArrowBackIosNewIcon
                        onClick={() => handleArrowClick(-1)}
                        sx={{
                            ...styles.styleArrowButton,
                            borderTopLeftRadius: defaultBorderRadius,
                            borderBottomLeftRadius: defaultBorderRadius,
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`,
                        }}
                    />
                </Grid>
            )}
            <Grid item sx={{width: "250px"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" sx={{color: temaEscuro && BORDER_BUTTON}}>Período</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={periodo}
                        label="Período"
                        size="small"
                        onChange={handleChange}
                        sx={{borderRadius: 0, textAlign: "center", backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                        renderValue={(value) => {
                            if (specificDate) {
                                return specificDate;
                            } else {
                                return EXMDefaultFilters.find(filter => filter.value === value).label;
                            }
                        }}
                    >
                        {EXMDefaultFilters.map((filter, index) => (
                            <MenuItem key={index} value={filter.value}>{filter.option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {periodo !== "custom_periodo" && periodo !== "todo_periodo" && (
                <Grid item>
                    <ArrowForwardIosIcon
                        onClick={() => handleArrowClick(1)}
                        sx={{
                            ...styles.styleArrowButton,
                            borderTopRightRadius: defaultBorderRadius,
                            borderBottomRightRadius: defaultBorderRadius,
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`,
                        }}
                    />
                </Grid>
            )}
            {
                periodo === "custom_periodo" && (
                    <Grid item sx={{marginLeft: 2}}>
                        <GrayButton
                            onClick={() => callFunction()}
                            title="Filtrar"
                            testId="button-filtrar"
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export default FilterEXM;

const styles = {
    styleArrowButton: {
        color: YELLOW_BG_UX,
        fontWeight: "600",
        boxShadow: "none",
        height: "21.5px",
        p: 1,
        cursor: "pointer",
        fontSize: "15px",
    }
};