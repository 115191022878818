import React, {useState} from "react";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@mui/icons-material/Menu";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import List from "@mui/material/List";
import ListItems from "./listItems";
import {
	GRAY_LABEL_UX_THEME,
	BLACK_TABLE_PERFIL,
	BLUE_THEME,
	GRAY_BG_UX,
	BORDER_BUTTON,
	translateAutocomplete,
	WHITE_TABLE,
    WHITE_ESCRITA_THEME
} from "../../shared/utils";
import Box from "@mui/material/Box";
import {Outlet} from "react-router-dom";
import logoMobileDark from "../../assets/images/logo_mobile_main.svg";
import logoMobileWhite from "../../assets/images/logo_mobile_main_white.svg";
import Paper from "@mui/material/Paper";
import {
	changeStore,
	changeModified
} from "../../actions/StoreActions";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import FormControl from "@mui/material/FormControl";

const MenuMobile = (props) => {

    const {store, lojas, setOpenSubItemInfo, setOpenSubItemConc, setOpenSubItemArq, openSubItemArq, openSubItemConc, openSubItemInfo, isPerfil, setIsPerfil, filteredSteps, loadingLojas} = props;

    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    const handleChangeOpenMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

    const handleChange = (obj) => {
		dispatch(changeModified(""));
		dispatch(changeStore(obj.value));
	};

    return (
        <Grid container fullWidth data-testid="menu-mobile">
            <Grid item xs={12} sx={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE, ...styles.container}}>
                <img src={temaEscuro ? logoMobileDark : logoMobileWhite} style={{width: "35px"}} />
                
                <FormControl sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "40%"}}>
                    {loadingLojas ? (
                        <Loading />
                    ) : (
                        lojas.length ? (
                            <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                disablePortal
                                disableClearable
                                data-testid="navbar-change-store"
                                PaperComponent={({ children }) => (
                                    <Paper
                                        style={{
                                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                            color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
                                            fontWeight: "500",
                                        }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={store || null}
                                options={lojas}
                                noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Loja"
                                        InputLabelProps={{
                                            style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}}}
                                    />}
                                sx={{width: "100%"}}
                            />
                        ) : (
                            <p>Nenhuma loja encontrada</p>
                        )
                        )
                    }

                </FormControl>
                
                <Button size="small" onClick={handleChangeOpenMenu} data-testid="menu-button" sx={{"&.MuiButton-root": {minWidth: 0, padding: 0}}}>
                    <MenuIcon sx={{...styles.button_menu, color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME}} />
                </Button>
            </Grid>
            <Grid item>
                <Menu isOpen={isMenuOpen} data-testid="menu" onStateChange={(state) => setIsMenuOpen(state.isOpen)} styles={{ 
                    bmMenu: { 
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        marginTop: "64px",
                    },
                }}>
                    <List component="nav">
                        <ListItems
                            setIsMenuOpen={setIsMenuOpen}
                            isDesktop={false}
                            open={open}
                            isPerfil={isPerfil}
                            setIsPerfil={setIsPerfil}
                            className={filteredSteps.map((x) => {return x.selector.replace(".", "");})}
                            setOpenSubItemInfo={setOpenSubItemInfo}
                            setOpenSubItemConc={setOpenSubItemConc}
                            setOpenSubItemArq={setOpenSubItemArq}
                            openSubItemArq={openSubItemArq}
                            openSubItemConc={openSubItemConc}
                            openSubItemInfo={openSubItemInfo}/>
                    </List>
                </Menu>
            </Grid>
            <Grid item sx={{...styles.container_main}}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === (temaEscuro ? "temaEscuro" : "light")
                                ? GRAY_LABEL_UX_THEME
                                : BLACK_TABLE_PERFIL,
                        
                        ...styles.box
                    }} //MUDA O FUNDO DE TODAS AS ABAS
                >
                    <Outlet />
                </Box>
            </Grid>
        </Grid>
    );
};

export default MenuMobile;

const styles = {
    container: {
        height: "64px", 
        width: "100%", 
        position: "fixed", 
        zIndex: 1000, 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        padding: 2
    },
    button_menu: {
        width: "auto", 
        height: "40px",
        cursor: "pointer",
    },
    container_main: {
        width: "100%", 
        height: "100vh"
    },
    box: {
        flexGrow: 1,
        overflowY: "auto",
        overflowX: "auto",
        width: "100%",
        height: "100%",
    }
};