import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX
} from "../../shared/utils";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import InputsApelidoTipo from "./InputsApelidoTipo";

const FormContaCorrente = (props) => {

    const {
        bancos,
        setInputBanco,
        inputBanco,
        inputModalidade,
        setInputModalidade,
        modalidades,
        isContaPadrao,
        setIsContaPadrao,
        inputTipoConta,
        setInputTipoConta,
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        isBancoEdit
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <React.Fragment>
           <InputsApelidoTipo
                inputTipoConta={inputTipoConta}
                isBancoEdit={isBancoEdit}
                contaDetails={contaDetails}
                setInputTipoConta={setInputTipoConta}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
            />
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Banco</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputBanco}
                    label="Banco"
                    defaultValue={contaDetails.banco}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputBanco(e.target.value)}
                >
                    {bancos.map((banco) => {
                        return (
                            <MenuItem data-testid="menu-item" key={banco} value={banco}>
                                {banco}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Modalidade</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputModalidade}
                    label="Banco"
                    defaultValue={contaDetails.modalidade}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputModalidade(e.target.value)}
                >
                        {modalidades.map((mod) => {
                        return (
                            <MenuItem data-testid="menu-item" key={mod} value={mod}>
                                {mod}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControlLabel 
                control={
                    <Checkbox
                        checked={isContaPadrao}
                        onChange={(e) => setIsContaPadrao(e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                } 
                label="Conta Padrão"
                sx={{
                    "& .MuiTypography-root": {
                        color: temaEscuro && WHITE_ESCRITA_THEME,
                        fontSize: "15px",
                    }
                }}
            />
        </React.Fragment>
    );
};

export default React.memo(FormContaCorrente);