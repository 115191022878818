import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import {useSelector} from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {BLACK_LABEL_UX, GRAY_LABEL_UX, WHITE_ESCRITA_THEME, BLUE_THEME,YELLOW_BG_UX,
	GRAY_BG_UX, BLACK_SUBITEM_NAVBAR, WHITE_SUBITEM_NAVBAR} from "../../shared/utils.js";
import "./listItem.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import AddCardIcon from "@mui/icons-material/AddCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreIcon from "@mui/icons-material/Store";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const ListItems = (props) => {
	const location = useLocation();
	const {open, className, setIsMenuOpen, isDesktop} = props;
	const [activeButton, setActiveButton] = useState("dashboard");
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const isOperator = useSelector(state => state.AuthReducer.isOperator);
	const isApprover = useSelector(state => state.AuthReducer.isApprover);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
	const infoArray = ["dre", "dfc", "painel", "consolidada", "compras", "cmv", "fluxo_caixa"];
	const concArray = ["portais", "conciliacao_taxa", "pdv", "resumo_ifood"];
	const indexTour = isAdmin ? 0 : 1;

	useEffect(() => {
		const path = location.pathname.split("/")[1];
		if (infoArray.includes(path)) {
			props.setOpenSubCompras(true);
		} else if (concArray.includes(path)) {
			props.setOpenSubFinanceiro(true);
		}
	}, []);

	useEffect(() => {
		let path = location.pathname.replace("/", "");
		if (path === "perfil") {
			setActiveButton("perfil");
		}
		if (path === "despesas") {
			setActiveButton("despesas");
		}
		if (path === "certificados") {
			setActiveButton("certificados");
		}
		if (path === "contas" || path === "nova_conta") {
			setActiveButton("contas");
		}
		if (path === "notas_compras") {
			setActiveButton("notas_compras");
		}
		if (path === "extrato_movimentacoes") {
			setActiveButton("extrato_movimentacoes");
		}
	}, [location]);

	const styleOpen = () => {
		if (open) {
			return styles.ListItem;
		} else {
			return styles.ListItemAlt;
		}
	};

	const styleColor = (pathList) => {
		if(temaEscuro===true){
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : WHITE_ESCRITA_THEME};
		}else{
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : GRAY_LABEL_UX};
		}
	};

	const styleSelected = (pathList, isSubItem) => {
		if (isSubItem) {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}

		} else {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : BLUE_THEME};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : GRAY_BG_UX};
			}
		}
	};

	const handleClick = (local) => {
		if (local === "compras") {
			props.setOpenSubCompras(!props.openSubCompras);
		} else if (local === "financeiro") {
			props.setOpenSubFinanceiro(!props.openSubFinanceiro);
		}
	};

	const handleChangeClick = (local) => {
		setActiveButton(local);
		if (!isDesktop) {
			setIsMenuOpen(false);
		}
	};

	return (
		<React.Fragment>
			<Link to="/dashboard" className="text-link" role="item">
				<ListItemButton
					style={{
						...styleOpen(),
						...styleSelected(["dashboard", ""]),
					}}
					onClick={() => handleChangeClick("dashboard")}
					className={className[0]}
				>
					<ListItemIcon>
						<HomeOutlinedIcon style={styleColor(["dashboard", ""])}/>
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography
								component="span"
								style={styleColor(["dashboard", ""])}
							>
								Resumo
							</Typography>
						}
					/>
				</ListItemButton>
			</Link>
			{
				(isOperator || isApprover || isAdmin) && (
					<Link to={`/despesas?estabelecimento_id=${estabelecimentoId}`} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["despesas"]),

							}}
							onClick={() => handleChangeClick("despesas")}
							className={className[1]}
						>
							<ListItemIcon>
								<AddCardIcon
									style={styleColor(["despesas"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["despesas"])}
									>
										Despesas
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			}
			{
				isAdmin && (
					<Link to={"certificados"} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["certificados"]),

							}}
							onClick={() => handleChangeClick("certificados")}
							className={className[1]}
						>
							<ListItemIcon>
								<WorkspacePremiumIcon
									style={styleColor(["certificados"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["certificados"])}
									>
										Certificados
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			}
			<ListItemButton onClick={() => handleClick("compras")} style={{color: YELLOW_BG_UX, marginTop: "15px"}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubCompras ?  <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary="Compras" />)
				}
			</ListItemButton>
			<Collapse in={props.openSubCompras} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to="/notas_compras" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["notas_compras"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("notas_compras")}
							className={className[3 - indexTour]}
						>
							<ListItemIcon>
								<ListAltOutlinedIcon
									style={styleColor(["notas_compras"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["notas_compras"])}
									>
										Notas de Compras
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
			<ListItemButton onClick={() => handleClick("financeiro")} sx={{color: YELLOW_BG_UX}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubFinanceiro ? <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary={"Financeiro"} />)
				}
			</ListItemButton>
			<Collapse in={props.openSubFinanceiro} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
				<Link to="/extrato_movimentacoes" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["extrato_movimentacoes"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("extrato_movimentacoes")}
							className={className[9 - indexTour]}
						>
							<ListItemIcon>
								<AccountBalanceIcon
									style={styleColor(["extrato_movimentacoes"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["extrato_movimentacoes"])}
									>
										EXM
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/contas" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["contas"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("contas")}
							className={className[10 - indexTour]}
						>
							<ListItemIcon>
								<StoreIcon
									style={styleColor(["contas"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["contas"])}
									>
										Contas
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
		</React.Fragment>
	);
};

const styles = {
	ListItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "10px 0px 10px 8px",
		width: "208px",
		height: "44px",
		borderRadius: "8px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "10px",
	},

	ListItemAlt: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0px 10px 43px",
		gap: "12px",
		width: "40px",
		height: "40px",
		borderRadius: "8px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "8px",
	},

	Administrativo: {
		width: "133px",
		height: "24px",
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "16px",
		lineHeight: "24px",
		color: "#2D3748",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "20px",
	},
	divider: {
		marginLeft: "20px",
		marginRight: "20px",
		borderColor: "unset",
		my: 2
	},
	styleIcon: {
		color: BLACK_LABEL_UX
	},
};

export default ListItems;
