export const steps = [
    {
        selector: ".button_1", 
        content: "O objetivo dessa aba é mostrar, por meio de gráficos e indicadores-chaves (KPI),  um resumo de como está a saúde financeira da empresa."
    },
    {
        selector: ".button_2", 
        content: "Para consultar, adicionar e alterar o status dos boletos das lojas."
    },
    {
        selector: ".button_3", 
        content: "DRE (Demonstração do Resultado do Exercício) – O objetivo dessa aba é evidenciar os indicadores financeiros do negócio, categorizando a movimentação mensal da sua empresa, onde podemos medir se a empresa obteve lucro ou prejuízo ao final dela. Os lançamentos financeiros da DRE são pelo regime de competência."
    },
    {
        selector: ".button_4", 
        content: "DFC (Demonstração do Fluxo de Caixa) – Podemos medir a saúde financeira da sua empresa. Nela são evidenciadas as movimentações no caixa."
    },
    {
        selector: ".button_5", 
        content: "Painel - Mostra a origem das receitas e das taxas das adquirentes, apps de delivery e vouchers."
    },
    {
        selector: ".button_6", 
        content: "Caso tenha mais de uma loja, é possível selecionar todas as lojas e filtrar pelos meses para ter uma DRE consolidada das empresas."
    },
    {
        selector: ".button_7", 
        content: "Apresenta gráficos comparativos, podendo representar a evolução de um indicador ao longo do tempo ou a comparação entre dois indicadores, podendo eles ser baseados em valores de mercado ou em valores da própria empresa."
    },
    {
        selector: ".button_8", 
        content: "Apresenta o relatório de compras do mês selecionado, por regime de competência, contendo informações da nota fiscal de compra, como os valores e quantidades dos itens comprados. Neste relatório constam somente os itens comprados com NFe."
    },
    {
        selector: ".button_9", 
        content: "Apresenta o fluxo de caixa diário, com base nas informações do Conta Azul (para as despesas realizadas e projetadas, e receitas realizadas) e nas informações dos portais das adquirentes, vouchers e apps de delivery (para as receitas projetadas)."
    },
    {
        selector: ".button_10", 
        content: "EXM - Apresenta o extrato de movimentações (EXM) da loja, podendo filtrar os dados de acordo com o período desejado."
    },
    {
        selector: ".button_11", 
        content: "Conciliação – Levantamento feito para a conciliação das adquirentes, vouchers e apps de delivery, comparando a informação do sistema frente de caixa com a informação das plataformas e verificando também se os valores depositados estão corretos."
    },
    {
        selector: ".button_12", 
        content: "Taxas – Conciliação das taxas de contrato das adquirentes."
    },
    {
        selector: ".button_13", 
        content: "Resumo Ifood - Apresenta o resumo financeiro dos dados do Ifood de determinada loja."
    },
    {
        selector: ".button_14", 
        content: "Para criar ou verificar uma ata de reunião."
    },
    {
        selector: ".button_15", 
        content: "O objetivo dessa aba é disponibilizar os dados extraídos de cada plataforma."
    },
    {
        selector: ".button_16", 
        content: "Para consultar, alterar ou criar um plano de contas para o cliente."
    },
    {
        selector: ".button_17", 
        content: "Apresenta todos os logins encaminhados pelo cliente."
    },
    {
        selector: ".button_18", 
        content: "Aba com o intuito de auxiliar na resolução de diversos problemas de dados que as lojas possam ter."
    },
    {
        selector: ".button_19", 
        content: "Possibilita a visualização e edição das suas informações cadastradas no sistema, como, por exemplo, a alteração da senha."
    },
];