import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    LINE_TABLE
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import {showSnackMessage} from "../../../actions/SnackActions";

const StepThreeComum = (props) => {

    const {inputApelido, createConta, setRenderSuccess, renderSuccess, tipoConta} = props;

    const dispatch = useDispatch();

    const [messageError, setMessageError] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        createConta(tipoConta)
            .then(response => {
                if (response.status === 201) {
                    setRenderSuccess(true);
                }
            })
            .catch(error => {
                if (error.response.status === 403) {
                    dispatch(showSnackMessage({ message: error.response.data, severity: "error" }));
                    setMessageError(error.response.data);
                } else {
                    dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
                }
            });
    }, []);

    return (    
        <Grid container spacing={2}>
            {
                renderSuccess ? (
                    <React.Fragment>
                        <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                            <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>Configuração da conta concluída com sucesso! {inputApelido}</h3>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                            {
                                messageError !== "" ? (
                                    <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>{messageError}</h3>
                                ) : (
                                    <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700, textAlign: "center"}}>Algo deu errado, por favor tente novamente</h3>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 1}}>
                            <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>O cadastro da sua conta foi malsucedido!</span>
                        </Grid>
                    </React.Fragment>
                )
            }
        </Grid>
    );
};

export default React.memo(StepThreeComum);