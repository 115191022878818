export const defaultFilters = [
    {
        "label": "1 mês atrás",
        "value": 1
    },
    {
        "label": "3 meses atrás",
        "value": 3
    },
    {
        "label": "6 meses atrás",
        "value": 6
    },
    {
        "label": "9 meses atrás",
        "value": 9
    },
    {
        "label": "1 ano atrás",
        "value": 12
    },
    {
        "label": "2 anos atrás",
        "value": 24
    }
];

export const EXMDefaultFilters = [
    {
        "label": "Hoje",
        "value": "hoje"
    },
    {
        "label": "Esta semana",
        "value": "esta_semana"
    },
    {
        "label": "Este mês",
        "value": "este_mes"
    },
    {
        "label": "Este ano",
        "value": "este_ano"
    },
    {
        "label": "Últimos 30 dias",
        "value": "ultimos_trinta_dias"
    },
    {
        "label": "Últimos 12 meses",
        "value": "ultimos_doze_meses"
    },
    {
        "label": "Todo o período",
        "value": "todo_periodo"
    },
    {
        "label": "Período personalizado",
        "value": "custom_periodo"
    },
];